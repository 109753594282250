import * as constants from '../constants/Constants';
import { theme } from '../helpers/GlobalStyles';
import { isNullOrUndefined } from './validationHelper';


export const calculateTread = (i, c, o) => {
  const res = {
    tread: {
      inner: i,
      center: c,
      outer: o,
    },
    minimum: { min: null, color: constants.EMPTY_STRING },
  };
  // Sets minimum of I, C and O
  res.minimum.min = Math.min(i, c, o);
  // Sets Color
  if (res.minimum.min >= 0 && res.minimum.min < 3) {
    res.minimum.color = `${theme.colors.red}`;
  } else if (res.minimum.min >= 3 && res.minimum.min < 5) {
    res.minimum.color = `${theme.colors.orange}`;
  } else if (res.minimum.min >= 5) {
    res.minimum.color = `${theme.colors.green}`;
  }
  return res;
};

export const maxTreadDepthHandler = (value) => {
  if (Number(value) > 32) {
    return value.replace(value, constants.MAX_TREAD_DEPTH);
  }
  return value;
};

export const validateDOTNumber = (dot) => {
  let res = true;
  for (let i = dot.length - 1; i > dot.length - 5; i--) {
    (dot.charCodeAt(i) > 57 || dot.charCodeAt(i) < 48) && (res = false);
  }
  return res;
};

export const checkIfTireTreadFilled = (treadDetails) => {
  let res = false;
  for (const k in treadDetails) {
    if (!isNullOrUndefined(treadDetails[k]?.minimum?.min)) {
      res = true;
    }
  }
  return res;
};

export const calculateAge = (dot) => {
  const dotWeek = Number(dot?.[dot?.length - 4] + dot?.[dot?.length - 3]);
  const dotMonth = dotWeek && (Math.round(dotWeek / 4.25) || 1);
  const dotYear = Number(dot?.[dot?.length - 2] + dot?.[dot?.length - 1]);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear() % 100;
  if (dotMonth && ((currentYear === dotYear && dotMonth <= currentMonth)
    || (dotYear < currentYear && dotMonth <= 12))) {
    const age = (((currentYear - dotYear - 1) * 12) + (12 - dotMonth + 1) + currentMonth) / 12;
    return Math.round(age);
  }
  return '0';
};
// Exports data format that table/summary uses
export const customerVehicleDetails = (c, query) => {
  let v;
  if (query.vin) {
    v = c?.vehicleCollection?.filter(veh => query.vin
    === veh.vehicleItem?.vin)[0]?.vehicleItem;
  }
  if (query.licensePlate) {
    v = c?.vehicleCollection.filter(veh => query.licensePlate
    === veh.vehicleItem?.licensePlateNumber)[0]?.vehicleItem;
  }
  return { ...c, ...v };
};

// Exports data format that table/summary uses
export const vehicleMatchUp = (vehSelected, veh) => {
  if (vehSelected.id === veh.id) return veh;
  if (vehSelected.type !== constants.VEHICLE_TYPE_GENERIC) {
    if (vehSelected.year === veh.year && vehSelected.make === veh.make && vehSelected.model === veh.model) {
      return veh;
    }
  } else {
    if (vehSelected?.overrideMake && vehSelected?.overrideModel && veh.overrideMake && veh.overrideModel) {
      if (vehSelected.overrideYear === veh.overrideYear && vehSelected.overrideMake === veh.overrideMake && vehSelected.overrideModel === veh.overrideModel) {
        return veh;
      }
    }

    if (vehSelected?.overrideVehicleSubCategory && vehSelected.overrideVehicleSubCategory === veh.overrideVehicleSubCategory && vehSelected.overrideVehicleCategory === veh.overrideVehicleCategory) {
      return veh;
    }
    if (vehSelected.overrideVehicleCategory === veh.overrideVehicleCategory && !vehSelected.overrideVehicleSubCategory) {
      return veh;
    }
  }
};
