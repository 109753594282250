import {
  useState, useEffect, useContext, useRef
} from 'react';
import { HiSearch } from 'react-icons/hi';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import {
  AiOutlineMinus, AiOutlinePlus, AiOutlineFilter, AiFillCloseCircle
} from 'react-icons/ai';
import { BsFillStopwatchFill, BsStopwatchFill } from 'react-icons/bs';
import TableBody from './TableBody';
import { theme } from '../../helpers/GlobalStyles';
import SearchInput from '../Input/SearchInput';
import * as constants from '../../constants/Constants';
import { CVMContext } from '../../components/CVM/CVM';
import { checkForEmptyObject, isNullEmptyUndefined } from '../../helper/validationHelper';
import { tableColumnSorting } from '../../helper/tableColumnSorting';
import searchFilter from '../../helper/searchFilter';
import styled from '../../helpers/esm-styled-components';
import CalendarDatePicker from '../Calendar/Calendar';
import { defaultInspectionQuery } from '../../helper/baseDataHelper';
import FilterPill from '../FilterPills/FilterPills';

const TableSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 120px); /* Account for header height */
`;

const SearchContainer = styled.div`
  top: 0;
  background-color: ${theme.colors.white};
  position: sticky;
  height: 100px;
  z-index: 5;
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding-left: 1rem;
  align-items: center;
  flex-wrap: no-wrap;
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TableSearchInput = styled(SearchInput)`
   width: 16rem !important;
`;

const ClearFilters = styled.div`
  display: flex;
  margin-left: 44rem;
  align-items: center;
  cursor: ${({ filterActive }) => (filterActive ? 'pointer' : 'not-allowed')};;
  color: ${({ filterActive }) => (filterActive ? theme.colors.red : theme.colors.mediumGray)};
 `;

const FilterIcon = styled.img`
  margin-left: 2rem;
  filter: invert(19%) sepia(98%) saturate(1942%) hue-rotate(208deg) brightness(95%) contrast(103%);
  cursor: pointer;
`;
const StyledHiSearch = styled(HiSearch)`
  font-size: ${theme.fontSize.large};
  margin-top:2.5%;
  margin-left: -7%;
  pointer-events: none;
`;

const ResultsTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  border-collapse: collapse;
  border-spacing: 0px;
  background-color: #fff;
  ${({ tableType }) => (tableType === constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE && 'max-height: 330px;')};
`;

const AiFillCloseCircleIcon = styled(AiFillCloseCircle)`
  margin-right: 5px;
  color: ${({ filterActive }) => (filterActive ? theme.colors.red : theme.colors.mediumGray)};
`;

const FilterText = styled.p`
  margin-left: 5px;
  color: ${theme.colors.blue};
  font-weight: ${theme.fontFamily.bold};
  font-size: ${theme.fontSize.large};
  cursor: pointer;
`;

const FormContainer = styled.div`
  position: absolute;
  z-index: 10;
  left:35rem;
  top:5rem;
  border: 1px solid grey;
  box-shadow: 0 0 5px grey;
  padding-right: 70px;
  background-color: white;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const Form = styled.form`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

const RadioLabel = styled.label`
  margin-bottom: 10px;
`;

const RadioTitle = styled.label``;

const ExpandCollapseHeader = styled.div`
  font-size: ${theme.fontSize.modalTitle};
  font-family: ${theme.fontFamily.heavy};
  color: ${theme.colors.linkBlue};
  padding: 0 62px 11px 20px;
  cursor: pointer;
  white-space: nowrap;
`;

const TableHeaders = styled.div`
  margin-top:${({ tableType }) => (tableType === constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE && '35px')};
  font-size: ${theme.fontSize.modalTitle};
  font-family: ${theme.fontFamily.heavy};
  color: ${theme.colors.obsidian};
  padding: 0 62px 11px 0px;
  top: 80px;
  z-index: ${({ isExpandedView }) => (isExpandedView ? '4' : '5')}; // Set z-index to -1 when expanded
  text-align: left;
  position: sticky;
  display: flex;
  align-items: center;
  white-space: nowrap;
  ${({ isExpandedView }) => isExpandedView &&
  `color:${theme.colors.editBlue}; cursor:pointer; font-size:${theme.fontSize.large}; font-family:${theme.fontFamily.bold}`};
  //Setting for Customer search change based on tableType
  ${({ tableType, index, previousOrigin }) => {
    if (tableType === constants.CUSTOMER_RESULTS_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 300px 10px 20px';
        case 2:
          return 'padding-right: 235px';
        case 3:
          return 'padding-right: 45px';
        case 4:
          return 'padding: 0 30px 10px 20px;';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.VEHICLES_RESULTS_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 220px 11px 50px';
        case 1:
          return 'padding-right: 138px';
        case 2:
          return 'padding-right: 110px';
        case 3:
          return 'padding-right: 25px';
        case 4:
          return 'padding-right: 40px';
        case 5:
          return 'padding-right: 50px';
        case 6:
          return 'padding-right: 15px';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.VTV_RESULTS_TABLE || tableType === constants.AIRCHECK_RESULTS_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 155px 10px 20px';
        case 1:
          return 'padding-right: 220px';
        case 2:
          return 'padding-right: 45px';
        case 3:
          return 'padding-right: 45px';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.DEACTIVATED_VEHICLES_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 225px 11px 141px';
        case 1:
          return 'padding-right: 150px';
        case 2:
          return 'padding-right: 260px';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.ACTIVE_VEHICLES_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 329px 11px 80px;';
        case 1:
          return 'padding-right: 125px';
        case 2:
          return 'padding-right: 150px';
        default:
          return constants.EMPTY_STRING;
      }
    }
    if (tableType === constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE) {
      switch (index) {
        case 0:
          return 'padding: 0 329px 11px 80px;';
        case 1:
          return 'padding-right: 412px';
        default:
          return constants.EMPTY_STRING;
      }
    }
  }}
`;

const TableHeaderRow = styled.div`
  display: flex;
  top: 100px;
  background-color: ${theme.colors.white};
  position: static;
  border-bottom: 1px solid black;
`;
// Icons
export const DownSortIcon = styled(FaChevronDown)`
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${theme.colors.searchGray};
  margin-left: 9px;
  cursor: pointer;
`;
export const UpSortIcon = styled(FaChevronUp)`
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${theme.colors.searchGray};
  margin-left: 9px;
  cursor: pointer;
`;
const PlusHeaderIcon = styled(AiOutlinePlus)`
  position:relative;
`;
const MinusHeaderIcon = styled(AiOutlineMinus)`
  position:relative;
`;

const LegendContainer = styled.div`
  display: flex;
  gap: 24px;
  padding: 16px 50px;
  border-top: 1px solid ${theme.colors.gray};
  background-color: ${theme.colors.white};
  min-width: 1400px;
  justify-content: center;
  position: sticky;
  bottom: 0;
  z-index: 10;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${theme.colors.darkGray};
`;

const YellowStopwatch = styled(BsStopwatchFill)`
  color: ${theme.colors.yellow};
  font-size: 18px;
`;

const RedStopwatch = styled(BsFillStopwatchFill)`
  color: ${theme.colors.red};
  font-size: 18px;
`;

const YellowTireIcon = styled.img`
  width: 18px;
  height: 18px;
  filter: invert(72%) sepia(98%) saturate(461%) hue-rotate(359deg) brightness(101%) contrast(107%);
`;

const RedTireIcon = styled.img`
  width: 18px;
  height: 18px;
  filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(112%);
`;

const Table = (props) => {
  const {
    headers,
    detailHeaders,
    dataResults,
    hasSearch,
    tableType,
    scrollHandler,
  } = props;
  const {
    searchOrigin,
    setDVRSelected,
    setSearchQuery,
    filter,
    filters,
    selectedValue,
    selectedDate,
    filtersTurnedOn,
    setFilter,
    setFilters,
    setSelectedDate,
    setFiltersTurnedOn,
    setSelectedValue
  } = useContext(CVMContext);
  // Temporarily copying the object, for local mutations 
  // but first checking to see if dataResults exists as to not crash the system
  let data;
  if (dataResults) {
     data = [...dataResults];

  } else {
    data = [];
  }
  const isInspectionTable = tableType === constants.VTV_RESULTS_TABLE || tableType === constants.AIRCHECK_RESULTS_TABLE;
  const [tableSearchResults, setTableSearchResults] = useState(data);
  const [sortedDirection, setSortedDirection] = useState(true);
  const [tableHasSort, setTableHasSort] = useState((tableType === constants.DEACTIVATED_VEHICLES_TABLE
    || tableType === constants.ACTIVE_VEHICLES_TABLE
    || tableType === constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE
    || tableType === constants.VTV_RESULTS_TABLE
    || tableType === constants.AIRCHECK_RESULTS_TABLE));
  const isTableFilter = tableType == constants.CUSTOMER_RESULTS_TABLE || tableType == constants.VEHICLES_RESULTS_TABLE || isInspectionTable;
  const [expandedInfo, setExpandedInfo] = useState(false);
  const currentDate = new Date();
  const hasExpand = tableType === constants.CUSTOMER_RESULTS_TABLE || tableType === constants.VEHICLES_RESULTS_TABLE || tableType === constants.VTV_RESULTS_TABLE || tableType === constants.AIRCHECK_RESULTS_TABLE;
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedTreadDepth, setSelectedTreadDepth] = useState(null);
  const [selectedTireAge, setSelectedTireAge] = useState(null);
  const clearFilters = () => { 
    if (isInspectionTable) handleDateChange(new Date());
    setFilters(0);
    setFiltersTurnedOn({ isRadioOn: false, isCalendarOn: false });
    setSelectedValue('both');
    setSelectedTreadDepth(null);
    setSelectedTireAge(null);
    setFilter(filter);
  };
  const sortingHandler = (filteredData) => {
    if (!tableHasSort) {
      return filteredData || data;
    }
    return tableColumnSorting(filteredData || data, () => {
      switch (tableType) {
        case constants.VTV_RESULTS_TABLE:
          return { dataType: constants.COLUMN_DATA_TYPE_TIME, columnPropName: 'vtvTimeCompleted' };
        case constants.AIRCHECK_RESULTS_TABLE:
          return { dataType: constants.COLUMN_DATA_TYPE_TIME, columnPropName: 'airCheckTimeCompleted' };
        case constants.ACTIVE_VEHICLES_TABLE:
        case constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE:
        case constants.DEACTIVATED_VEHICLES_TABLE:
          return {
            dataType: [constants.COLUMN_DATA_TYPE_NUMBER, constants.COLUMN_DATA_TYPE_STRING, constants.COLUMN_DATA_TYPE_STRING],
            columnPropName: ['year', 'make', 'model']
          };
        default:
          break;
      }
    }, sortedDirection);
  };
  const setSort = () => {
    setSortedDirection(!sortedDirection);
  };
  const handleRemoveFilter = (filterType) => {
    setFiltersTurnedOn((prev) => {
        const updatedFilters = { ...prev };

        switch (filterType) {
            case 'customerType':
                setSelectedValue('both'); // Reset to default or 'both' if applicable
                updatedFilters.isRadioOn = false;
                break;
            case 'treadDepth':
                setSelectedTreadDepth(null); // Reset the selected tread depth
                updatedFilters.isTireTreadFilterOn = false;
                break;
            case 'tireAge':
                setSelectedTireAge(null); // Reset the selected tire age
                updatedFilters.isTireAgeFilterOn = false;
                break;
            default:
                return prev; // Return unchanged state if the filterType isn't recognized
        }
        return updatedFilters;
    });

    setFilters((prev) => Math.max(0, prev - 1));
};

  const sort = () => (sortedDirection ? <DownSortIcon onClick={setSort} /> : <UpSortIcon onClick={setSort} />);
  const expand = () => (expandedInfo ? <MinusHeaderIcon/> : <PlusHeaderIcon/>);
  const expandAll = () => { setExpandedInfo(!expandedInfo); };
  const onSearch = (event) => {
    if (!expandedInfo && event.target.value.length > 0) {
      expandAll();
    }
    if (event.target.value.length > 0) {
      setDVRSelected(null);
    }
    if (event.target.value.length == 0) {
      setExpandedInfo(!expandedInfo);
    }
    setFilter(event.target.value.toLowerCase());
  };

  useEffect(() => {
   setFilter('');
  }, []);

  useEffect(() => {
    // Refactored useEffect to remove unnecessary event listener logic and focus purely on filtering/sorting data.  
 // Improved readability by handling empty filters early and adding relevant dependencies for reactivity.

    if (isNullEmptyUndefined(filter)) {
      setTableSearchResults(sortingHandler());
      return;
    }
    setTableSearchResults(sortingHandler(searchFilter(searchOrigin, tableType, data, filter)));
  }, [filter, dataResults, selectedValue, sortedDirection]);

  useEffect(() => {
    setSelectedDate(new Date());
    clearFilters();
  }, [tableType]);

  useEffect(() => {
    // SET DATA HERE FOR REFLECT IN TABLE BODY
    if (isInspectionTable) {
      data = data?.map((c) => {
        // NOTE: Other workstreams are still using the lastName workaround for customer type, remove once thats fixed
        if (c.customer?.lastName === constants.BUSINESS_CUSTOMER_TYPE || c.customer?.customerType === constants.BUSINESS_CUSTOMER_TYPE) {
          c.customerType = constants.BUSINESS_CUSTOMER_TYPE;
        } else {
          c.customerType = constants.STANDARD_CUSTOMER_TYPE;
        }
        return c;
      });
    }
    // Customer type filtering will be handled in the combined filter useEffect
  }, [dataResults]);
  
  useEffect(() => {
    // Apply all filters together in a single pass
    let filteredData = [...data]; // Start with a fresh copy of the original data
    
    // Map customer types if needed
    if (isInspectionTable) {
      filteredData = filteredData?.map((c) => {
        // Create a new object to avoid modifying the original
        const newC = {...c};
        if (c.customer?.lastName === constants.BUSINESS_CUSTOMER_TYPE || c.customer?.customerType === constants.BUSINESS_CUSTOMER_TYPE) {
          newC.customerType = constants.BUSINESS_CUSTOMER_TYPE;
        } else {
          newC.customerType = constants.STANDARD_CUSTOMER_TYPE;
        }
        return newC;
      });
    }
    
    // Apply customer type filter if active
    if (filtersTurnedOn.isRadioOn && (selectedValue === 'B2B' || selectedValue === 'B2C')) {
      filteredData = filteredData.filter(c => c.customerType === selectedValue);
    }
    
    // Apply tire tread depth and tire age filters
    if ((filtersTurnedOn.isTireTreadFilterOn && selectedTreadDepth) || 
        (filtersTurnedOn.isTireAgeFilterOn && selectedTireAge)) {
      
      filteredData = filteredData.filter(inspection => {
        // Skip if no tire collection
        if (!inspection?.tireCollection?.length) return false;
        
        // Check for tread depth issues if that filter is active
        const hasTreadDepthIssue = filtersTurnedOn.isTireTreadFilterOn && selectedTreadDepth ? 
          inspection?.tireCollection?.some(tire => {
            // Get the lowest tread depth reading from inner/center/outer
            const readings = tire.tireItem.treadDepthCollection
              ?.map(depth => depth.treadDepthItem.reading)
              ?.filter(reading => reading !== null)
              ?.map(reading => parseFloat(reading));
            
            const lowestReading = readings?.length > 0 ? Math.min(...readings) : null;
            
            // If we have any readings, check if any are below critical threshold
            return readings?.length > 0 && lowestReading <= constants.TIRE_TREAD_DEPTH_CRITICAL;
          }) : false;
        
        // Check for tire age issues if that filter is active
        const hasTireAgeIssue = filtersTurnedOn.isTireAgeFilterOn && selectedTireAge ?
          inspection.tireCollection.some(tire => {
            const tireAge = tire.tireItem.tireAge;
            // Check if tire age is above warning threshold
            return tireAge !== null && parseFloat(tireAge) >= constants.TIRE_AGE_WARNING;
          }) : false;
        
        // Return true if either condition is met (OR logic)
        return hasTreadDepthIssue || hasTireAgeIssue;
      });
    }
    
    // Update table results
    if (isNullEmptyUndefined(filter)) {
      setTableSearchResults(sortingHandler(filteredData));
    } else {
      setTableSearchResults(sortingHandler(searchFilter(searchOrigin, tableType, filteredData, filter)));
    }
  }, [filter, dataResults, selectedValue, sortedDirection, selectedTreadDepth, selectedTireAge, filtersTurnedOn]);
  const handleRadioChange = (event) => {
    setIsExpanded(false);
    const radioSelection = event.target.value;
    const type = event?.target?.getAttribute('data-type');
    
    // Handle different filter types
    switch (type) {
      case 'treadDepth':
        if (selectedTreadDepth === radioSelection) {
          // If already selected, unselect it
          setSelectedTreadDepth(null);
          setFiltersTurnedOn(prev => ({...prev, isTireTreadFilterOn: false}));
          setFilters(prev => Math.max(0, prev - 1));
        } else {
          // Select new value
          setSelectedTreadDepth(radioSelection);
          setFiltersTurnedOn(prev => ({...prev, isTireTreadFilterOn: true}));
          if (!filtersTurnedOn.isTireTreadFilterOn) {
            setFilters(prev => prev + 1);
          }
        }
        break;
      case 'tireAge':
        if (selectedTireAge === radioSelection) {
          // If already selected, unselect it
          setSelectedTireAge(null);
          setFiltersTurnedOn(prev => ({...prev, isTireAgeFilterOn: false}));
          setFilters(prev => Math.max(0, prev - 1));
        } else {
          // Select new value
          setSelectedTireAge(radioSelection);
          setFiltersTurnedOn(prev => ({...prev, isTireAgeFilterOn: true}));
          if (!filtersTurnedOn.isTireAgeFilterOn) {
            setFilters(prev => prev + 1);
          }
        }
        break;
      case 'customerType':
        // For customer type, preserve existing behavior
        if (selectedValue === radioSelection) {
          // If already selected, unselect it
          setSelectedValue('both');
          setFiltersTurnedOn(prev => ({...prev, isRadioOn: false}));
          setFilters(prev => Math.max(0, prev - 1));
        } else {
          // Select new value
          setSelectedValue(radioSelection);
          setFiltersTurnedOn(prev => ({...prev, isRadioOn: true}));
          if (!filtersTurnedOn.isRadioOn) {
            setFilters(prev => prev + 1);
          }
        }
        break;
      default:
        break;
    }
  };
  const handleDateChange = (date) => {
    if (date.getDate() !== currentDate.getDate() && !filtersTurnedOn.isCalendarOn) {
      setFilters(filters + 1);
      setFiltersTurnedOn({ ...filtersTurnedOn,  isCalendarOn: true });
    } else if (date.getDate() === currentDate.getDate() && filtersTurnedOn.isCalendarOn) {
      setFilters(filters - 1);
      setFiltersTurnedOn({ ...filtersTurnedOn,  isCalendarOn: false });
    }
    setSelectedDate(date);
    const query = defaultInspectionQuery(date);
    setSearchQuery(query);
  };
  const isDisabledDate = (date) => {
    /* Disable Sundays */
    if (date.getDay() === 0) {
      return true;
    }
    // Enable today and every day prior to today from the previous month to today
    const today = new Date();
    const previousMonth = new Date(today);
    previousMonth.setMonth(today.getMonth() - 1);
    return date > today || date < previousMonth;
  };

  /* Filter Logic */
  const typeFilter = () => {
    setIsExpanded(prevState => !prevState);
  };

  const formRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <TableSearchContainer>
      {hasSearch && (
        <SearchContainer>
          <SearchWrapper>
            {isInspectionTable && (
              <CalendarDatePicker selectedDate={selectedDate} handleDateChange={handleDateChange} disabledDates={isDisabledDate} currentDate={currentDate}/>
            )}
            <TableSearchInput 
              width={isInspectionTable ? '16rem;' : '32rem'} 
              onSearch={(event) => onSearch(event)} 
              isTableSearch={true}
            />
            <StyledHiSearch isInspectionTable={isInspectionTable} />
            {isTableFilter && (
              <>
                <FilterIcon
                  onClick={typeFilter}
                  src="/GenericVehicleImages/filter_transparent.svg"
                  alt="generic filter icon"
                />
                <FilterText onClick={typeFilter}>
                  {constants.FILTER} ({filters})
                </FilterText>
                {filters > 0 && (
                  <>
                    {filtersTurnedOn.isRadioOn && selectedValue === 'B2C' && (
                      <FilterPill label="B2C" onRemove={() => handleRemoveFilter('customerType')} />
                    )}
                    {filtersTurnedOn.isRadioOn && selectedValue === 'B2B' && (
                      <FilterPill label="B2B" onRemove={() => handleRemoveFilter('customerType')} />
                    )}
                    {filtersTurnedOn.isTireTreadFilterOn && (
                      <FilterPill label="Tread Depth" onRemove={() => handleRemoveFilter('treadDepth')} />
                    )}
                    {filtersTurnedOn.isTireAgeFilterOn && (
                      <FilterPill label="Tire Age" onRemove={() => handleRemoveFilter('tireAge')} />
                    )}
                    <FilterPill 
                      label={`Clear Filters (${filters})`} 
                      onRemove={clearFilters}
                      isClear={true}
                    />
                  </>
                )}
                <FormContainer isVisible={isExpanded} ref={formRef}>
                  <Form>
                    <p>{constants.FILTER_BY}</p>
                    {[
                      {
                        label: 'B2B',
                        value: 'B2B',
                        name: 'customerType',
                        type: 'customerType'
                      },
                      {
                        label: 'B2C',
                        value: 'B2C',
                        name: 'customerType',
                        type: 'customerType'
                      },
                      ...(tableType === constants.VTV_RESULTS_TABLE || tableType === constants.AIRCHECK_RESULTS_TABLE ? [
                        {
                          label: 'Tread Depth',
                          value: '4',
                          name: 'treadDepth',
                          type: 'treadDepth'
                        },
                        {
                          label: 'Tire Age',
                          value: '6',
                          name: 'tireAge',
                          type: 'tireAge'
                        }
                      ] : [])
                    ].map((option) => (
                      <RadioLabel key={option.id} htmlFor={option.value}>
                        <input
                          type="checkbox"
                          name={option.name}
                          value={option.value}
                          checked={
                            (option.type === 'customerType' && option.value === selectedValue) || 
                            (option.type === 'treadDepth' && option.value === selectedTreadDepth) ||
                            (option.type === 'tireAge' && option.value === selectedTireAge)
                          }  
                          onChange={handleRadioChange}
                          data-type={option.type}
                        />
                        <RadioTitle
                          onClick={() => handleRadioChange({
                            target: { 
                              value: option.value,
                              getAttribute: () => option.type 
                            },
                          })}
                        >
                          {option.label}
                        </RadioTitle>
                      </RadioLabel>
                    ))}
                  </Form>
                </FormContainer>
              </>
            )}
          </SearchWrapper>
        </SearchContainer>
      )}
      <TableHeaderRow isExpandedView={isExpanded}>
        {headers.map((c, index) => (
          <TableHeaders tableType={tableType} index={index} key={index}>
            {(c === constants.CUSTOMER_DEACTIVATED_VEHICLES[0]
              || c === constants.CUSTOMER_ADDITIONAL_ACTIVE_VEHICLES[0])
              && `${tableSearchResults?.length} `}
            {c}
            {(c === constants.CUSTOMER_DEACTIVATED_VEHICLES[0]
              || c === constants.CUSTOMER_ADDITIONAL_ACTIVE_VEHICLES[0]
              || c === constants.VTV_SEARCH_RESULTS_HEADERS[4]
              || c === constants.AIRCHECK_SEARCH_RESULTS_HEADERS[4])
              && sort()}
          </TableHeaders>
        ))}
        {hasExpand && tableSearchResults.length > 1 && (
          <ExpandCollapseHeader onClick={expandAll}>
            {expandedInfo ? constants.COLLAPSE_ALL : constants.EXPAND_ALL}
          </ExpandCollapseHeader>
        )}
      </TableHeaderRow>
      <ResultsTable tableType={tableType}>
        <TableBody
          data={tableSearchResults}
          filter={filter}
          selectedValue={selectedValue}
          detailHeaders={detailHeaders}
          tableType={tableType}
          isExpandedView={expandedInfo}
          hasExpand={hasExpand}
          scrollHandler={scrollHandler}
        />
      </ResultsTable>
      {(tableType === constants.VTV_RESULTS_TABLE || tableType === constants.AIRCHECK_RESULTS_TABLE) && (
        <LegendContainer>
          <LegendItem>
            <YellowStopwatch />
            Tire Age Warning
          </LegendItem>
          <LegendItem>
            <RedStopwatch />
            Tire Age Critical
          </LegendItem>
          <LegendItem>
            <YellowTireIcon src="./VehicleImages/tire-solid.svg" alt="Yellow Tire Warning" />
            Tread Depth Warning
          </LegendItem>
          <LegendItem>
            <RedTireIcon src="./VehicleImages/tire-solid.svg" alt="Red Tire Warning" />
            Tread Depth Critical
          </LegendItem>
        </LegendContainer>
      )}
    </TableSearchContainer>
  );
};
export default Table;
