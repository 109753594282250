import React from 'react';
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';
import { HiSearch } from 'react-icons/hi';

const SearchContainer = styled.div`
  position: relative;
  width: ${props => props.width || '100%'};
`;

const SearchInputContainer = styled.input`
  width: 100%;
  height: 3.3rem;
  border: 1px solid ${theme.colors.obsidian};
  padding: ${props => props.isTableSearch ? '13px' : '15px'};
  padding-right: 50px;
  font-size: 15px;
  font-family: Lato Regular;
  font-weight: bold;
  color: ${theme.colors.obsidian};
  position: relative;
`;

const SearchIcon = styled(HiSearch)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: ${theme.colors.obsidian};
  font-size: 20px;
`;

const SearchInput = ({ width, onSearch, isTableSearch }) => {
  return (
    <SearchContainer width={width}>
      <SearchInputContainer 
        onChange={(event) => onSearch(event)} 
        isTableSearch={isTableSearch}
        placeholder="Search"
      />
      <SearchIcon />
    </SearchContainer>
  );
};

export default SearchInput;
