/**
 * Holds a list of functions that are utilized throughout the standard vehicle add/edit process
 * */

import moment from 'moment';
import { APIRouter } from '../../../../Data/APIRouter';
import {
  capitalizeWord,
  checkIfObjectHasKey,
  formatNumeric,
  isNullEmptyUndefined,
  validateVIN,
} from '../../../../helper/validationHelper';
import {
  isViewAllVehiclesEnabled,
} from '../../../../helper/CustomerHelper';
import {
  addCustomerVehicleStructure,
  getFormattedInspectionData,
  getVehicleInfo,
  oneTimeVehicleInfo,
  removeEmptyDecimal,
  vtvAircheckBaseData,
} from '../../../../helper/vehicleFormValidation';
import * as constants from '../../../../constants/Constants';
import { countryCodeOptions, countryOptions, vehicleObjectsAreEqual } from '../../../../helper/baseDataHelper';
import { publishCVData } from '../../../../helper/gkHelper';

/**
 * Normalizes a value to a number, returning 0 for falsy values
 * @param {number|string|null|undefined} value - The value to normalize
 * @returns {number} - The normalized number or 0
 */
const normalizeNumber = (value) => value ? Number(value) : 0;

/**
 * Normalizes a value to a string, returning empty string for falsy values
 * @param {string|null|undefined} value - The value to normalize
 * @returns {string} - The normalized string or empty string
 */
const normalizeEmptyValue = (value) => value || '';

export const deactivateOneTimeUseVehicles = (customerVehicles) => {
  customerVehicles?.forEach((vehicle) => {
    if (!vehicle.endDate && vehicle.isOneTime) {
      vehicle.endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    }
    if (
      vehicle.isOneTime &&
      (moment(vehicle.endDate).format('YYYY-MM-DD') <
        moment().format('YYYY-MM-DD') ||
        !vehicle.endDate)
    ) {
      vehicle.isDeactivated = true;
    }
  });
};

export const isVehicleActive = (vehicle) => {
  return !isVehicleInactive(vehicle);
};

export const isVehicleInactive = (vehicle) => {
  return API.utils.isFalse(vehicle?.isActive) ||
    API.utils.isTrue(vehicle?.isDeactivated);
};

export const splitActiveInactiveVehicles = (vehicles) => {

  const activeVehicles = [];
  const inactiveVehicles = [];

  vehicles?.forEach((v) => {
    if (isVehicleActive(v)) {
      activeVehicles.push(v);
    } else {
      inactiveVehicles.push(v);
    }
  });

  return [activeVehicles, inactiveVehicles];
};

export const restructureImportedVehicle = (importedVehicleObj, type) => {
  if (!importedVehicleObj) return;

  // restructure field values to match dropdowns
  let fields = Object.keys(importedVehicleObj).map(f => {
    // capitalize color if comes in lowercase
    if (f === 'color') {
      if (typeof importedVehicleObj.color === 'string') {
        importedVehicleObj.color = importedVehicleObj.color?.charAt(0) == importedVehicleObj.color?.charAt(0)?.toLowerCase() ? capitalizeWord(importedVehicleObj.color) : importedVehicleObj.color;
      }
      return f;
    }
    if (f === 'year' || f === 'make' || f === 'model') {
      return f;
    }
  });

  fields = fields.filter(f => f !== undefined);
  // check if YMMTC are objects. If not, convert them
  fields.forEach(field => {
    switch (type) {
      case 'object':
        if (typeof importedVehicleObj[field] === 'string') {
          importedVehicleObj[field] = { label: importedVehicleObj[field], value: importedVehicleObj[field] };
        }
        break;
      case 'string':
        if (typeof importedVehicleObj[field] === 'object') {
          importedVehicleObj[field] = importedVehicleObj[field].value;
        }
        break;
      default:
        break;
    }
  });
};

/**
 *
 * @param {Object} vehicle
 * @returns auto sync data with colors and images
 */
export const getAutoSyncData = async (vehicle, setLoader) => {
  const colorsAndImages = await APIRouter(
    'FIT',
    'getVehicleImages',
    {
      year: vehicle.year,
      make: vehicle.make,
      model: vehicle.model,
      trim: vehicle.trimId
    },
    setLoader,
  );

  if (!colorsAndImages) {
    return undefined;
  }

  const colorsWithImages = colorsAndImages?.Vehicles ? colorsAndImages.Vehicles[0]?.ColorsWithImages : colorsAndImages[0]?.ColorsWithImages;

  colorsWithImages?.sort((a, b) => {
    if (a.Name > b.Name) {
      return 1;
    }
    if (a.Name < b.Name) {
      return -1;
    }
    return 0;
  });

  return colorsWithImages;
};

/**
 * helper function to look up vins and licenses in C360 and MDM/IHS
 * @param {Object} vehicleSpec
 * @returns {Object} Returns an object containing the search origin (vin or license),
 * location vehicle was found (ihs or C360), and the vehicle object.
 */
export const ihsLookUp = async ({ vin, license = null }, setLoader) => {
  // license search
  const licenseInfo = {
    licensePlate: license?.licensePlateNumber,
    licenseState: license?.licensePlateStateCode,
    licenseCountry: license?.licensePlateCountryCode,
  };
  if (license) {
    const res = await APIRouter(
      'C360',
      'getVehicleDetails',
      { ...licenseInfo },
      setLoader,
      false
    );
    // If IHS returns a false then send this back
    if (res?.statusMessage === constants.IHS_STATUS_MESSAGE_FAILED_TO_FIND_MATCH) {
      return {
        search: 'license',
        loca: null,
        vehicle: null,
        error: res.statusMessage,
      };
    } if (checkIfObjectHasKey(res, 'getVehicleDetails')) {
      return {
        search: 'license',
        loc: null,
        vehicle: null,
      };
    }
    // return this vehicle object
    return {
      search: 'license',
      loc: 'ihs',
      vehicle: res?.vehicle,
    };
  }

  // vin search
  const res = await APIRouter('C360', 'getVehicle', { vin }, setLoader, false);
  // Not found in C360
  if (checkIfObjectHasKey(res, 'getVehicle')) {
    const res = await APIRouter(
      'C360',
      'getVehicleDetails',
      { vin },
      setLoader,
      false
    );
    // Not found in IHS
    if (res?.statusMessage === constants.IHS_STATUS_MESSAGE_FAILED_TO_FIND_MATCH) {
      return {
        search: 'vin',
        loca: null,
        vehicle: null,
        error: res.statusMessage,
      };
    } if (checkIfObjectHasKey(res, 'getVehicleDetails')) {
      return {
        search: 'vin',
        loc: null,
        vehicle: null,
      };
    }
    // found in IHS
    return {
      search: 'vin',
      loc: 'ihs',
      vehicle: res.vehicle,
    };
  }
  // if C360 has a vehicle with IHS set to false, need to check IHS to confirm.
  // If IHS has it, set the vehicle flag to isIhs: true before handing back
  let ihsFlag = false;
  // TO DO: Business wants to filter for isIHS: true and !Generic && !type===null do not check IHS
  const ihsCheckResult = await APIRouter(
    'C360',
    'getVehicleDetails',
    { vin },
    setLoader,
    false
  );
  // if failed to find fitmnet match, set vehicle as null
  if (ihsCheckResult?.statusMessage?.toString()?.indexOf('Fitment Vehicle Matches failed to find a match') > -1 || ihsCheckResult?.getVehicleDetails === null) {
    return {
      search: 'vin',
      loc: null,
      vehicle: null,
      error: 'error'
    };
  }

  ihsFlag = !checkIfObjectHasKey(ihsCheckResult, 'getVehicleDetails');
  let vehicleTemp;
  // If not found in IHS
  if (checkIfObjectHasKey(ihsCheckResult, 'getVehicleDetails')) {
    // found in C360 and not in IHS
    vehicleTemp = {
      search: 'vin',
      loc: 'c360',
      vehicle: res.customer.vehicleCollection[0]?.vehicleItem,
    };
    vehicleTemp.vehicle.isIhs = ihsFlag;
  } else {
    // found in IHS
    vehicleTemp = {
      search: 'vin',
      loc: 'ihs',
      vehicle: ihsCheckResult?.vehicle,
    };
    vehicleTemp.vehicle.isIhs = ihsFlag;
  }
  return vehicleTemp;
};

// restructure assembly collection for payload
const assemblyItem = (standardVehicleFields) => {
  const existingAssemblyItems = JSON.parse(
    JSON.stringify(standardVehicleFields.assemblyCollection)
  );
  // remove extraneous fields
  existingAssemblyItems.forEach((a) => {
    delete a.assemblyItem.tireSize;
    delete a.assemblyItem.wheelSize;
    delete a.assemblyItem.isOE;
    delete a.assemblyItem.isAfterMarket;
    delete a.assemblyItem.isStaggered;
    delete a.assemblyItem.isWinterUse;
  });

  //  Make sure rear tire values have proper defaults.
  existingAssemblyItems.forEach((a) => {
    a.assemblyItem.rearTireWidth = a.assemblyItem.rearTireWidth || '';
    a.assemblyItem.rearTireAspectRatio = a.assemblyItem.rearTireAspectRatio || '';
    a.assemblyItem.rearTireDiameter = a.assemblyItem.rearTireDiameter || '';
    a.assemblyItem.rearWheelSize = a.assemblyItem.rearWheelSize || '';
    a.assemblyItem.rearRimSize = a.assemblyItem.rearRimSize || '';
  });

  // sets the currentAssembly field for each assemblyItem
  existingAssemblyItems.forEach(eai => {
    // staggered check
    if (eai.assemblyItem.frontTireWidth !== eai.assemblyItem.rearTireWidth) {
      eai.assemblyItem.currentAssembly = eai.assemblyItem?.assemblyType === standardVehicleFields.selectedAssembly[0]?.assemblyType;
    } else {
      eai.assemblyItem.currentAssembly = eai.assemblyItem?.assemblyType === standardVehicleFields.selectedAssembly?.assemblyType;
    }
  });
  return existingAssemblyItems;
};

// builds the standard vehicle object to be sent to the add or update custome vehicle end point
export const buildStandardVehicleObject = (
  isActive,
  ihs,
  standardVehicleFields,
  tireDetails,
  isEdit,
  selectedVehicle,
  additionalFieldsToSend,
) => {
  let vehicleInfo = {
    year:
      parseInt(
        checkIfObjectHasKey(standardVehicleFields.year, 'value')
          ? standardVehicleFields.year.value
          : standardVehicleFields.year,
        10
      ) || null,
    type: 'Fitment',
    model: checkIfObjectHasKey(standardVehicleFields.model, 'value')
      ? standardVehicleFields.model.value
      : standardVehicleFields.model,
    make: checkIfObjectHasKey(standardVehicleFields.make, 'value')
      ? standardVehicleFields.make.value
      : standardVehicleFields.make,
    vin: standardVehicleFields?.vin?.toLowerCase() === 'carryout' ? constants.EMPTY_STRING : standardVehicleFields.vin || constants.EMPTY_STRING,
    licensePlateNumber: standardVehicleFields.licensePlate?.toLowerCase() === 'carryout' ? constants.EMPTY_STRING : standardVehicleFields?.licensePlate,
    licensePlateStateCode: standardVehicleFields.licenseState?.value
      ? `${
        standardVehicleFields.licenseCountry?.value === constants.USA
          ? constants.US
          : standardVehicleFields.licenseCountry?.value || constants.US
      }-${standardVehicleFields.licenseState?.value}`
      : constants.EMPTY_STRING,
    licensePlateCountryCode: `${
      checkIfObjectHasKey(standardVehicleFields.licenseCountry, 'value')
        ? standardVehicleFields.licenseCountry?.value === constants.USA
          ? constants.US
          : standardVehicleFields.licenseCountry === constants.USA
            ? constants.US
            : checkIfObjectHasKey(standardVehicleFields.licenseCountry, 'value')
              ? standardVehicleFields.licenseCountry?.value
              : standardVehicleFields.licenseCountry
        : constants.US
    }`,
    color: standardVehicleFields.color?.value,
    chassisCode: `${
      standardVehicleFields.chassisId || standardVehicleFields.chassisCode
    }`,
    fitmentLiftedId: standardVehicleFields.trimLift,
    trimId: standardVehicleFields.trimId,
    overrideModel: constants.EMPTY_STRING,
    overrideMake: constants.EMPTY_STRING,
    imageUrl: standardVehicleFields.imageUrl || constants.EMPTY_STRING,
    trimDescription: standardVehicleFields.trim,
    isDualRearWheel: standardVehicleFields.isDually || false,
    overrideTrim: constants.EMPTY_STRING,
    fitmentVehicleId: Array.isArray(standardVehicleFields.selectedAssembly) ? standardVehicleFields.selectedAssembly[0].fitmentId : standardVehicleFields.selectedAssembly.fitmentId,
    acesVehicleId: constants.EMPTY_STRING,
    acesBodyType: constants.EMPTY_STRING,
    isIhs: ihs || false,
    isCarryOut: standardVehicleFields.isCarryOut || false,
    acesDriveId: constants.EMPTY_STRING,
    overrideVehicleCategory: constants.EMPTY_STRING,
    overrideVehicleSubCategory: constants.EMPTY_STRING,
    overrideFrontTirePressure: constants.EMPTY_STRING,
    overrideRearTirePressure: constants.EMPTY_STRING,
    overrideBoltPattern: constants.EMPTY_STRING,
    overrideLugNut: constants.EMPTY_STRING,
    overrideTorque: constants.EMPTY_STRING,
    isWheelLocked: false,
    isStaggered: Array.isArray(standardVehicleFields.selectedAssembly),
    notes: standardVehicleFields.notes || constants.EMPTY_STRING,
    classificationCode: constants.EMPTY_STRING,
    isOfflineCreation: false,
    overrideDescription: constants.EMPTY_STRING,
    vehicleClass: constants.EMPTY_STRING,
    overrideYear:
      parseInt(
        checkIfObjectHasKey(standardVehicleFields.year, 'value')
          ? standardVehicleFields.year.value
          : standardVehicleFields.year,
        10
      ) || null,
    endDate: !isActive && moment().format('YYYY-MM-DD'),
    startDate: (!isEdit || !standardVehicleFields?.startDate) ? moment().format('YYYY-MM-DD') : standardVehicleFields.startDate,
    isActive,
    isOneTime: standardVehicleFields.isOneTime || false,
    vehicleTireData: tireDetails?.vehicleTireData,
    assemblyCollection: assemblyItem(standardVehicleFields),
    vehicleId: isEdit
      ? selectedVehicle?.vehicleDetails?.vehicleId ||
        selectedVehicle?.vehicleId || standardVehicleFields.vehicleId ||
        constants.EMPTY_STRING
      : constants.EMPTY_STRING,
    relationshipId: standardVehicleFields.relationshipId,
  };

  // This is needed since IHS is re-checked at the end of adding
  // and if the user just inputs license and the vehicle is found in IHS
  // we need to attach the vin to the payload, but can't add it to standardVehicleFields
  // without setting the state and causing async issues with the post
  additionalFieldsToSend?.forEach(af => {
    if (af.delete) {
      delete vehicleInfo[af.fieldName];
    } else {
      vehicleInfo[af.fieldName] = af.value;
    }
  });
  // remove end date if vehicle is active
  isActive && delete vehicleInfo.endDate;

  if (standardVehicleFields?.isOneTime) {
    vehicleInfo = oneTimeVehicleInfo(vehicleInfo);
  }

  return vehicleInfo;
};


//
// adds/updates fitment vehicle
//
export const addUpdateCustomerVehicle = async (
  e,
  isActive,
  ihs,
  csrSelected,
  vehSelected,
  setLoader,
  setIsModalOn,
  moduleName,
  setModuleName,
  setShowDeactivatedVehicles,
  setSelectedVehicle,
  selectedVehicle,
  standardVehicleFields,
  isEdit,
  additionalFieldsToSend,
  inspection,
  setVtvSelected,
  setAircheckSelected,
  setImportedVehicle,
  useImportedVehicle,
  cachedIhsVehicleDetails,
  standardVehicleFieldsNonIHS,
  publishCVDataCallBack,
) => {

  if (!isNullEmptyUndefined(inspection)) {
    if (!isNullEmptyUndefined(standardVehicleFields.vehicleId)) {
      isEdit = true;
    } else {
      isEdit = false;
    }
  }

  try {
    let customerInfo = {},
      vehicleInfo = {};
    if (csrSelected) {
      customerInfo = {
        customerId: csrSelected.id,
        firstName: csrSelected.firstName,
        lastName: csrSelected.lastName,
        customerType: csrSelected.customerType ? (csrSelected.customerType === 'Person' || csrSelected.customerType === constants.STANDARD_CUSTOMER_TYPE) ? constants.STANDARD_CUSTOMER_TYPE : constants.BUSINESS_CUSTOMER_TYPE : constants.EMPTY_STRING,
      };
    } else {
      customerInfo = {
        customerId: vehSelected.customerId || vehSelected.customer.customerId,
        firstName: vehSelected.firstName || vehSelected.customer.firstName,
        lastName: vehSelected.lastName || vehSelected.customer.lastName,
        customerType: vehSelected.customerType || 'B2C',
      };
    }
    const tireDetails = await APIRouter(
      'FIT',
      'getVehicleAttributes',
      {
        year: checkIfObjectHasKey(standardVehicleFields.year, 'value')
          ? standardVehicleFields.year.value
          : standardVehicleFields.year,
        make: checkIfObjectHasKey(standardVehicleFields.make, 'value')
          ? standardVehicleFields.make.value
          : standardVehicleFields.make,
        model: checkIfObjectHasKey(standardVehicleFields.model, 'value')
          ? standardVehicleFields.model.value
          : standardVehicleFields.model,
        // TODO: change back to trim once we go back to live data
        trim: standardVehicleFields.trimId,
        assembly: Array.isArray(standardVehicleFields.selectedAssembly)
          ? standardVehicleFields.selectedAssembly[0].assemblyCode
          : standardVehicleFields.selectedAssembly.assemblyCode || null,
      },
      setLoader,
      false
    );
    // build the vehicle object
    vehicleInfo = buildStandardVehicleObject(
      isActive,
      ihs,
      standardVehicleFields,
      tireDetails,
      isEdit,
      selectedVehicle,
      additionalFieldsToSend,
    );
    const params = [customerInfo, vehicleInfo];

    // TODO: (ss) Not sure why, but the return value from addCustomerVehicle
    // (and the original data for updateCustomerVehicle) both have access to
    // the vehicleId. That means we should just grab that and use it for the
    // re-fetch logic lower down instead of fetching all vehicles.

    //  If isEdit, get vehicle id from the vehicle that we're going to update.
    //  Otherwise, capture the response and get the vehicle id from the
    //  response.

    let res;

    //  TODO: 'params' should have the vehicle in it somewhere.
    //  TODO: If isEdit, the vehicleId will be with the vehicle, else it's in
    //  the response because it gets generated by the insert.

    res = await APIRouter(
      'C360',
      isEdit ? 'updateCustomerVehicle' : 'addCustomerVehicle',
      params,
      setLoader,
      false
    );
    if (res?.status !== 'success') {
      throw new Error(constants.VEHICLE_ADD_ERROR);
    }

    let vehicleId;

    //  vehicleId is obtained by either digging through the return value (in
    //  case of addCustomerVehicle) or by what was supplied to the call (it will
    //  be in the vehicle record that is supplied to the call).
    if (isEdit) {
      vehicleId = vehicleInfo?.vehicleId;
    } else {
      vehicleId = res?.customer?.vehicleCollection?.[0]?.vehicleItem?.vehicleId;
    }

    if (isNullEmptyUndefined(vehicleId)) {
      throw new Error(constants.VEHICLE_ADD_ERROR);
    }
    //  This is the vehicle.
    res = await APIRouter(
      'C360',
      'getCustomerVehicle',
      [customerInfo.customerId, vehicleId],
      setLoader,
      false
    );


    if (res) {
      const vehicleAdded = res;

      vehicleAdded.isCarryOut = standardVehicleFields.isCarryOut;
      setShowDeactivatedVehicles(false);
      setIsModalOn(false);
      if (moduleName === constants.MODULE_CUSTOMER_VEHICLES) {
        setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
      }

      if (isNullEmptyUndefined(vehicleAdded)) {
        throw new Error(constants.VEHICLE_ADD_ERROR);
      }

      // TODO: (ss) when fetching a single vehicle we already normalize,
      // so is this trims/etc. stuff even needed now?

      // set the vehicle as the selected vehicle
      const trims = await APIRouter('FIT', 'getVehicleTrims', ({ year: vehicleAdded?.year, make: vehicleAdded?.make, model: vehicleAdded?.model }), setLoader, true);
      const trimLiftId = trims?.vehicleTrim?.liftCollection.filter(t => t.liftItem.chassisId === vehicleAdded?.chassisCode)[0]?.liftItem?.liftIdCollection?.filter(lc => lc.liftIdItem.liftDescription === vehicleAdded.fitmentLiftedId)[0]?.liftIdItem?.liftId ?? null;
      vehicleAdded.trimLiftId = trimLiftId;

      // NOTE: PROD bug causing the application not to do full refresh
      if (!isNullEmptyUndefined(inspection) && standardVehicleFields.isIncompleteInspection) {
        const inspectionData = getFormattedInspectionData(vtvAircheckBaseData(inspection), { vehicleDetails: vehicleAdded, tireDetails }, csrSelected);
        const inspectionRes = await APIRouter('VTV', 'updateVTVInspection', inspectionData, setLoader);
        // NOTE: Relying on the way it used to assign inspection to selected vehicle
        // if (inspectionData.data === 'service') {
        //   setVtvSelected({ inspectionId: inspectionRes.inspectionId });
        // } else {
        //   setAircheckSelected({ inspectionId: inspectionRes.inspectionId });
        // }
        // setIsModalOn(false);
        // return;
        console.log(inspectionRes);
      }

      vehicleAdded.isIncomplete = false;

      // load autoSync data if applicable
      const autoSync = await getAutoSyncData(vehicleAdded, setLoader);

      // grab the autosync colors if autosync available
      let autoSyncData;
      if (autoSync && autoSync?.length > 0) {
        const autoSyncColor = autoSync.find(ac => ac.Image === vehicleAdded?.imageUrl);
        autoSyncData = {
          autoSync,
          autoSyncColor: autoSyncColor ? { value: autoSyncColor.Name, label: autoSyncColor.Name } : undefined,
        };
      }

      vehicleAdded.autoSyncData = autoSyncData;
      if (cachedIhsVehicleDetails || vehicleAdded.isCarryOut) {
        vehicleAdded.vin = standardVehicleFieldsNonIHS?.vin || constants.EMPTY_STRING;
        vehicleAdded.licensePlateNumber = standardVehicleFieldsNonIHS?.licensePlate || constants.EMPTY_STRING;
        vehicleAdded.licensePlateStateCode = standardVehicleFieldsNonIHS?.licenseState?.value || 'AZ';
        vehicleAdded.licenseState = standardVehicleFieldsNonIHS?.licenseState?.value || 'AZ';
        vehicleAdded.licensePlateContryCode = standardVehicleFieldsNonIHS?.licenseCountry || constants.EMPTY_STRING;

      }

      // NOTE: used to check if publishCVDataCallBack is empty, if it is not empty then we call publishCVDataCallback
      if (!isNullEmptyUndefined(publishCVDataCallBack)) {
        publishCVDataCallBack({
          vehicleDetails: vehicleAdded,
          tireDetails,
          inspection
        });
      }

      setSelectedVehicle({
        vehicleDetails: vehicleAdded,
        tireDetails,
        inspection
      });

      useImportedVehicle && setImportedVehicle(null);

    } else {
      //  Is this an edit of an inactive vehicle?
      setIsModalOn(false);
      if (moduleName === constants.MODULE_CUSTOMER_VEHICLES) {
        setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
      }
      setSelectedVehicle(null);
      throw new Error(constants.VEHICLE_ADD_ERROR);
    }
  } catch (err) {
    //  TODO: display an error modal
    throw new Error(err.message);
  }
};

/**
 * Creates an assembly item based on tire fields and configuration
 * @param {Object} params - Assembly parameters
 * @param {Object} params.tireFields - Tire-related fields
 * @param {Object} [params.assembly] - Existing assembly data
 * @returns {Object} Assembled item configuration
 */
const createAssemblyItem = ({ tireFields, assembly = {} }) => {
  const inputRatio = removeEmptyDecimal(tireFields?.tireRatio);
  const inputDiameter = removeEmptyDecimal(tireFields?.tireDiameter);

  const baseAssembly = {
    assemblyCode: assembly?.assemblyCode || constants.EMPTY_STRING,
    assemblyId: assembly?.assemblyId || constants.EMPTY_STRING,
    assemblyType: assembly?.assemblyType || constants.VEHICLE_GENERIC_ASSEMBLY_TYPE,
    sizeQualifier: assembly?.sizeQualifier || constants.EMPTY_STRING,
    assemblyConfiguration: tireFields.assemblyConfiguration.value,
    frontTireWidth: tireFields.tireWidth,
    frontTireAspectRatio: inputRatio,
    frontTireDiameter: inputDiameter,
    frontRimSize: tireFields?.wheelWidth?.toString(),
    wheelWidth: tireFields.wheelWidth,
  };

  if (tireFields.assemblyConfiguration.value === 'staggered') {
    const rearInputRatio = removeEmptyDecimal(tireFields?.rearTireRatio);
    const rearInputDiameter = removeEmptyDecimal(tireFields?.rearTireDiameter);

    return {
      ...baseAssembly,
      rearTireWidth: tireFields.rearTireWidth,
      rearTireAspectRatio: rearInputRatio,
      rearTireDiameter: rearInputDiameter,
      rearWheelSize: rearInputDiameter,
      rearRimSize: tireFields?.rearWheelWidth?.toString(),
    };
  }

  return {
    ...baseAssembly,
    rearTireWidth: '',
    rearTireAspectRatio: '',
    rearTireDiameter: '',
    rearWheelSize: '',
    rearRimSize: '',
  };
};

/**
 * Creates the vehicle info object with all necessary details
 * @param {Object} params - Vehicle parameters
 * @param {Object} params.fields - Basic vehicle fields
 * @param {Object} params.tireFields - Tire-related fields
 * @param {Object} params.vehicleDetails - Existing vehicle details
 * @returns {Object} Vehicle information object
 */
const createVehicleInfo = ({ fields, tireFields, vehicleDetails }) => {
  return {
    ...vehicleDetails,
    type: constants.VEHICLE_TYPE_GENERIC,
    overrideDescription: vehicleDetails?.overrideDescription || constants.VEHICLE_TYPE_GENERIC,
    overrideVehicleCategory: fields.vehicleCategory,
    overrideVehicleSubCategory: fields?.vehicleSubCategory || constants.EMPTY_STRING,
    overrideTrim: fields?.trim,
    overrideYear: parseInt(fields.year, 10),
    overrideMake: fields.make,
    overrideModel: fields.model,
    overrideBoltPattern: tireFields.wheelBoltPattern,
    overrideRearBoltPattern: tireFields.rearWheelBoltPattern,
    overrideFrontTirePressure: tireFields.tirePressure,
    overrideRearTirePressure: tireFields.rearTirePressure,
    overrideLugNut: tireFields.wheelLugNutSize,
    overrideRearLugNut: tireFields.rearWheelLugNutSize,
    overrideTorque: tireFields.wheelTorque,
    overrideRearTorque: tireFields.rearWheelTorque,
    color: fields.color.label || constants.EMPTY_STRING,
    license: fields.licensePlate,
    licensePlateNumber: fields.licensePlate,
    licenseState: fields.licenseState.value || constants.EMPTY_STRING,
    licensePlateStateCode: fields?.licenseState?.value
      ? `${fields.licenseCountry.value ? fields.licenseCountry.value.slice(0, 2) : countryCodeOptions[0].value}-${fields.licenseState.value}`
      : constants.EMPTY_STRING,
    licenseCountry: fields.licenseCountry.value || countryOptions[0].value,
    licensePlateCountry: fields.licenseCountry.value || countryOptions[0].value,
    licensePlateCountryCode: fields.licenseCountry.value?.slice(0, 2) || countryOptions[0].value?.slice(0, 2),
    vin: fields.vin,
    isDeactivated: false,
    isCarryOut: fields.isCarryOut || false,
    isStaggered: tireFields.assemblyConfiguration.value === 'staggered',
    isDually: tireFields.assemblyConfiguration.value === 'dually',
    notes: tireFields.notes,
    imageUrl: fields.imageUrl,
  };
};

/**
 * Adds or updates a generic vehicle
 * @param {Object} params - Function parameters
 * @param {Function} params.setErrorMessage - Function to set error messages
 * @param {Function} params.setApplyCustomerVehicleError - Function to set customer vehicle error
 * @param {Object} params.tireFields - Tire-related fields
 * @param {Object} params.fields - Basic vehicle fields
 * @param {Object} params.vehicleDetails - Vehicle details
 * @param {Object} params.tireDetails - Tire details
 * @param {boolean} params.isEdit - Whether this is an edit operation
 * @param {Function} params.setLoader - Function to set loader state
 * @param {Function} params.setIsModalOn - Function to control modal visibility
 * @param {string} params.moduleName - Current module name
 * @param {Function} params.setModuleName - Function to set module name
 * @param {Object} params.selectedVehicle - Selected vehicle
 * @param {Function} params.setSelectedVehicle - Function to set selected vehicle
 * @param {Object} params.customerInfo - Customer information
 * @param {boolean} [params.isActive=true] - Whether the vehicle is active
 * @param {Object} params.inspection - Inspection data
 * @returns {Promise<void>}
 */
export const addUpdateCustomerGenericVehicle = async (
  // setErrorMessage = () => {},
  // setApplyCustomerVehicleError = () => {},
  setErrorMessage,
  tireFields,
  fields,
  vehicleDetails,
  tireDetails,
  isEdit,
  setLoader,
  setIsModalOn,
  moduleName,
  setModuleName,
  selectedVehicle,
  setSelectedVehicle,
  customerInfo,
  isActive = true,
  setImportedVehicle,
  useImportedVehicle,
  setFormFields,
  inspection,
  setVtvSelected,
  setAircheckSelected,
  aircheckSelected,
  transactionCustomer,
  setApplyCustomerVehicleError
) => {
  const errorHandler = setErrorMessage || (() => {});
  const customerVehicleErrorHandler = setApplyCustomerVehicleError || (() => {});
  // If the first argument is an object, treat it as the new style

  let isEditValue = isEdit;
  if (!isNullEmptyUndefined(inspection)) {
    isEditValue = !isNullEmptyUndefined(fields?.vehicleId);
  }

  if (isEditValue && fields?.isCarryOut) {
    const shouldReturn = handleCarryOutVehicle({
      fields,
      selectedVehicle,
      vehicleDetails,
      tireFields,
      customerInfo,
      inspection,
      aircheckSelected,
      transactionCustomer,
      setSelectedVehicle,
      setIsModalOn,
      setApplyCustomerVehicleError: customerVehicleErrorHandler,
      isEdit: isEditValue
    });

    if (shouldReturn) {
      return;
    }
  }

  // Validate vehicle fields
  if (!validateVehicleFields({ fields, setErrorMessage: errorHandler })) {
    return;
  }

  // Create vehicle info and assembly item
  const vehicleInfo = createVehicleInfo({ fields, tireFields, vehicleDetails });
  const assembly = isEditValue ? tireDetails?.vehicleTireData[0] : undefined;
  const assemblyItem = createAssemblyItem({ tireFields, assembly });
  vehicleInfo.assemblyCollection = [{ assemblyItem }];

  try {
    setLoader(true);

    // Prepare vehicle data for API
    let vehicleData = addCustomerVehicleStructure(customerInfo, vehicleInfo);

    if (fields?.oneTimeVehicle) {
      vehicleData.vehicleInfo = oneTimeVehicleInfo(vehicleData.vehicleInfo);
    }

    const isUpdateVehicle = isEditValue && (API.utils.notEmpty(vehicleDetails?.id) || API.utils.notEmpty(vehicleDetails?.vehicleId));

    if (isUpdateVehicle) {
      vehicleData.vehicleInfo.vehicleId = API.utils.ifInvalid(vehicleDetails.id, vehicleDetails.vehicleId);
    }

    // Handle activation state
    if (isEditValue) {
      if (isActive) {
        vehicleData.vehicleInfo.isActive = true;
        if (!vehicleData.vehicleInfo.isOneTime) {
          delete vehicleData.vehicleInfo.endDate;
        }
      } else {
        vehicleData.vehicleInfo.isActive = false;
        vehicleData.vehicleInfo.endDate = moment().format('YYYY-MM-DD');
      }
    }

    // Add or update vehicle
    const res = await APIRouter(
      'C360',
      isUpdateVehicle ? 'updateCustomerVehicle' : 'addCustomerVehicle',
      [vehicleData.customerInfo, vehicleData.vehicleInfo],
      setLoader
    );

    if (res?.status !== 'success') {
      throw new Error(constants.VEHICLE_ADD_ERROR);
    }

    // Get vehicle ID
    let vehicleId = isEditValue
      ? vehicleInfo?.vehicleId
      : res?.customer?.vehicleCollection?.[0]?.vehicleItem?.vehicleId;

    if (isNullEmptyUndefined(vehicleId)) {
      throw new Error(constants.VEHICLE_ADD_ERROR);
    }

    // Fetch updated vehicle info
    const vehicleRes = await APIRouter(
      'C360',
      'getCustomerVehicle',
      [res.customer.customerId, vehicleId],
      setLoader,
      false
    );

    if (!vehicleRes) {
      setIsModalOn(false);
      if (moduleName === constants.MODULE_CUSTOMER_VEHICLES) {
        setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
      }
      setSelectedVehicle(null);
      throw new Error(constants.VEHICLE_ADD_ERROR);
    }

    // Update vehicle info with latest data
    const updatedVehicleInfo = {
      ...vehicleRes,
      isCarryOut: fields.isCarryOut
    };

    // Get formatted tire details
    const formattedTireDetails = await getVehicleInfo(updatedVehicleInfo, setLoader);
    setFormFields(updatedVehicleInfo, formattedTireDetails?.vehicleTireData[0]);

    // Handle inspection if present
    if (!isNullEmptyUndefined(inspection)) {
      const inspectionData = getFormattedInspectionData(
        vtvAircheckBaseData(inspection),
        { vehicleDetails: updatedVehicleInfo, tireDetails: formattedTireDetails },
        customerInfo
      );
      const inspectionRes = await APIRouter('VTV', 'updateVTVInspection', inspectionData, setLoader);

      if (inspectionData.data === 'service') {
        setVtvSelected({ inspectionId: inspectionRes.inspectionId });
      } else {
        setAircheckSelected({ inspectionId: inspectionRes.inspectionId });
      }
    }

    // Update UI state
    setIsModalOn(false);
    if (moduleName === constants.MODULE_CUSTOMER_VEHICLES) {
      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
    }

    // Handle final vehicle selection
    if (isEditValue && !isActive) {
      setSelectedVehicle(null);
    } else {
      if (fields?.isCarryOut) {
        setSelectedVehicle({
          vehicleDetails: {
            ...updatedVehicleInfo,
            vin: constants.CARRYOUT,
            licensePlateNumber: constants.CARRYOUT,
            licenseState: 'AZ',
            isCarryOut: true,
          },
          tireDetails: formattedTireDetails,
        });
      } else {
        setSelectedVehicle({
          vehicleDetails: updatedVehicleInfo,
          tireDetails: formattedTireDetails,
        });
      }
    }

    // Clean up imported vehicle if needed
    if (useImportedVehicle) {
      setImportedVehicle(null);
    }

  } catch (error) {
    console.error('Error adding/updating vehicle:', error);
    if (setApplyCustomerVehicleError) {
      setApplyCustomerVehicleError(true);
    }
  } finally {
    setLoader(false);
  }
};

/**
 * Implementation of addUpdateCustomerGenericVehicle that uses the new parameter structure
 * @param {Object} config - The configuration object
 */

/**
 * Validates vehicle fields and sets appropriate error messages
 * @param {Object} params - The validation parameters
 * @param {Object} params.fields - The vehicle fields to validate
 * @param {Function} [params.setErrorMessage] - Optional function to set error messages
 * @param {boolean} [params.validateOptionalFields=false] - Whether to validate optional fields
 * @returns {boolean} - Returns true if validation passes, false otherwise
 */
const validateVehicleFields = ({
  fields,
  setErrorMessage = () => {}
}) => {
  // VIN validation - only validate if VIN is provided
  if (fields.vin && fields.vin.length > 0) {
    if (!validateVIN(fields.vin)) {
      setErrorMessage(constants.ERROR_MESSAGE_VIN);
      return false;
    }
  }

  return true;
};

const handleCarryOutVehicle = ({
  fields,
  selectedVehicle,
  vehicleDetails,
  tireFields,
  customerInfo,
  inspection,
  aircheckSelected,
  transactionCustomer,
  setSelectedVehicle,
  setIsModalOn,
  setApplyCustomerVehicleError = () => {},
  isEdit
}) => {
  const carryOutModified = {
    ...selectedVehicle,
    vehicleDetails: {
      ...selectedVehicle.vehicleDetails,
      vin: constants.CARRYOUT,
      licensePlateNumber: constants.CARRYOUT,
      licenseState: 'AZ',
      isCarryOut: true,
      color: fields.color?.value || constants.EMPTY_STRING,
      overrideYear: fields?.year,
      overrideMake: fields?.make,
      overrideModel: fields?.model
    }
  };

  setSelectedVehicle(carryOutModified);
  publishCVData(
    null,
    { customerInfo, selectedVehicle: carryOutModified },
    customerInfo,
    carryOutModified,
    inspection,
    aircheckSelected,
    transactionCustomer,
    null,
    isEdit,
    setIsModalOn,
    setSelectedVehicle,
    countryCodeOptions,
    setApplyCustomerVehicleError
  );
  setIsModalOn(false);

  const fieldsToCompare = {
    year: normalizeNumber(fields?.year),
    make: normalizeEmptyValue(fields?.make),
    model: normalizeEmptyValue(fields?.model),
    trim: normalizeEmptyValue(fields?.trim),
    color: fields?.color?.value?.toLowerCase(),
    ratio: normalizeNumber(tireFields?.tireRatio),
    diameter: normalizeNumber(tireFields?.tireDiameter),
    tirePressure: normalizeNumber(tireFields?.tirePressure),
    tireWidth: normalizeNumber(tireFields?.tireWidth),
    boltPattern: normalizeEmptyValue(tireFields?.wheelBoltPattern),
    lugNutSize: normalizeEmptyValue(tireFields?.wheelLugNutSize),
    rearTirePressure: normalizeNumber(tireFields?.rearTirePressure),
    ...(vehicleDetails?.isStaggered && {
      rearTireWidth: normalizeNumber(tireFields?.rearTireWidth),
      rearTireDiameter: normalizeNumber(tireFields?.rearTireDiameter),
      rearBoltPattern: normalizeEmptyValue(tireFields?.rearWheelBoltPattern),
      rearLugNutSize: normalizeEmptyValue(tireFields?.rearWheelLugNutSize),
    }),
    torque: normalizeNumber(tireFields?.wheelTorque),
    rearTorque: normalizeNumber(tireFields?.rearWheelTorque),
  };

  const vehicleDetailsToCompare = {
    year: normalizeNumber(vehicleDetails?.year),
    make: normalizeEmptyValue(vehicleDetails?.overrideMake),
    model: normalizeEmptyValue(vehicleDetails?.overrideModel),
    trim: normalizeEmptyValue(vehicleDetails?.overrideTrim),
    color: vehicleDetails?.color?.toLowerCase(),
    ratio: normalizeNumber(vehicleDetails?.assemblyCollection[0]?.assemblyItem?.frontTireAspectRatio),
    diameter: normalizeNumber(vehicleDetails?.assemblyCollection[0]?.assemblyItem?.frontTireDiameter),
    tirePressure: normalizeNumber(vehicleDetails?.overrideFrontTirePressure),
    tireWidth: normalizeNumber(vehicleDetails?.assemblyCollection[0]?.assemblyItem?.frontTireWidth),
    boltPattern: normalizeEmptyValue(vehicleDetails?.overrideFrontBoltPattern),
    lugNutSize: normalizeEmptyValue(vehicleDetails?.overrideFrontLugNut),
    rearTirePressure: normalizeNumber(vehicleDetails?.overrideRearTirePressure),
    ...(vehicleDetails?.isStaggered && {
      rearTireWidth: normalizeNumber(vehicleDetails?.assemblyCollection[0]?.assemblyItem?.rearTireWidth),
      rearTireDiameter: normalizeNumber(vehicleDetails?.assemblyCollection[0]?.assemblyItem?.rearTireDiameter),
      rearBoltPattern: normalizeEmptyValue(vehicleDetails?.overRideRearWheelBoltPattern),
      rearLugNutSize: normalizeEmptyValue(vehicleDetails?.overRideRearWheelLugNut),
    }),
    torque: normalizeNumber(vehicleDetails?.overrideFrontTorque),
    rearTorque: normalizeNumber(vehicleDetails?.overrideRearTorque),
  };

  return vehicleObjectsAreEqual(fieldsToCompare, vehicleDetailsToCompare);
};

//
// Reactivate/Deactivate Vehicles
//

const formatMileage = (value) => {
  if (typeof value !== 'string') value = value?.toString();
  const inputValue = formatNumeric(value)?.replace(/,/g, '').slice(0, 7);
  const formattedValue = inputValue && Number(inputValue).toLocaleString(constants.LOCALE);
  return formattedValue || constants.EMPTY_STRING;
};

export const reactivateDeactivateVehicle = async (
  customer,
  vehicle,
  vehicleId,
  relationshipId,
  type,
  status,
  setLoader,
  selectedVehicle,
  setSelectedVehicle,
  setModuleName,
  setIsModalOn,
  vehicles,
  deactivatedVehicles,
  setVehicles,
  setDeactivatedVehicles,
  setShowViewAllVehiclesLink
) => {

  let customerInfo = {},
    vehicleInfo = {};
  customerInfo = {
    customerId: customer?.id ? customer?.id : customer?.customerId || constants.EMPTY_STRING,
    customerType: customer.customerType ? (customer.customerType === 'Person' || customer.customerType === constants.STANDARD_CUSTOMER_TYPE) ? constants.STANDARD_CUSTOMER_TYPE : constants.BUSINESS_CUSTOMER_TYPE : constants.EMPTY_STRING,
  };

  vehicleInfo = {
    vehicleId,
    isActive: status === 'reactivate',
    endDate:
      status === 'reactivate' ? '2024-06-24' : moment().format('YYYY-MM-DD'),
    startDate: vehicle?.startDate || moment().format('YYYY-MM-DD'),
    isOneTime: false,
    relationshipId,
  };
  await APIRouter(
    'C360',
    'updateCustomerVehicle',
    [customerInfo, vehicleInfo],
    setLoader,
    false
  );

  let vehicleFound = await APIRouter(
    'C360',
    'getCustomerVehicles',
    [customerInfo.customerId, { vehicleId, normalize: true }],
    setLoader,
    false
  );

  if (isNullEmptyUndefined(vehicleFound)) {
    return;
  }
  vehicleFound = vehicleFound[0];

  if (status === 'reactivate') {
    if (type === 'Generic') {
      const formattedTireDetails = await getVehicleInfo(vehicleFound, setLoader);

      setSelectedVehicle({
        vehicleDetails: vehicleFound,
        tireDetails: formattedTireDetails,
      });

    } else {
      const tireDetails = await APIRouter(
        'FIT',
        'getVehicleAttributes',
        {
          year: checkIfObjectHasKey(vehicle.year, 'value')
            ? vehicle.year.value
            : vehicle.year,
          make: checkIfObjectHasKey(vehicle.make, 'value')
            ? vehicle.make.value
            : vehicle.make,
          model: checkIfObjectHasKey(vehicle.model, 'value')
            ? vehicle.model.value
            : vehicle.model,
          // TODO: change back to trim once we go back to live data
          trim: vehicle.trimId,
          assembly: Array.isArray(vehicle.selectedAssembly)
            ? vehicle.selectedAssembly[0].assemblyCode
            : vehicle.selectedAssembly.assemblyCode || null,
        },
        setLoader,
        false
      );

      const trims = await APIRouter('FIT', 'getVehicleTrims', ({ year: vehicleFound?.year, make: vehicleFound?.make, model: vehicleFound?.model }), setLoader, true);
      const trimLiftId = trims?.vehicleTrim?.liftCollection.filter(t => t.liftItem.chassisId === vehicleFound?.chassisCode)[0]?.liftItem?.liftIdCollection?.filter(lc => lc.liftIdItem.liftDescription === vehicleFound.fitmentLiftedId)[0]?.liftIdItem?.liftId ?? null;
      if (vehicleFound) {
        vehicleFound.trimLiftId = trimLiftId;
        vehicleFound.isIncomplete = false;
      }

      setSelectedVehicle({
        vehicleDetails: vehicleFound,
        tireDetails,
      });
    }
  } else {
    setSelectedVehicle(null);
  }

  let activeVehicles,
    inactiveVehicles;

  if (status === 'reactivate') {
    activeVehicles = vehicles.concat(vehicleFound);
    inactiveVehicles = deactivatedVehicles.filter(v => v.vehicleId !== vehicleId);
  } else {
    activeVehicles = vehicles.filter(v => v.vehicleId !== vehicleId);
    inactiveVehicles = deactivatedVehicles.concat(vehicleFound);
  }

  setVehicles(activeVehicles);
  setDeactivatedVehicles(inactiveVehicles);
  // setShowViewAllVehiclesLink(activeVehicles.length >= 3);
  setShowViewAllVehiclesLink(isViewAllVehiclesEnabled(
    activeVehicles.concat(inactiveVehicles), selectedVehicle));

  // Transition back to the summary page.
  setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
  setIsModalOn(false);

};

export const isGenericVehicleStandard = (vehicle) => {
  const {
    frontTireWidth, rearTireWidth, frontTireRatio, rearTireRatio, frontTireDiameter, rearTireDiameter
  } = vehicle;
  return !!(frontTireWidth === rearTireWidth && frontTireRatio === rearTireRatio && frontTireDiameter === rearTireDiameter);
};


export const vtvAircheckAddVehicle = (vehicle, stateOptions, isIncomplete) => {
  if (!isIncomplete) {
    // NOTE: removing for hotfix, no vin/license should populate
    // const stateInUS = stateOptions('USA').filter((state) => state.value === vehicle?.licensePlateState);
    // if (stateInUS.length) {
    //   vehicle.licensePlateState = stateInUS[0];
    //   vehicle.licensePlateCountry = 'USA';
    // } else {
    //   vehicle.licensePlateState = constants.EMPTY_STRING;
    // }
    vehicle.licenseState = '';
    vehicle.selectedAssembly = {
      assemblyCode: vehicle.assemblyId || constants.EMPTY_STRING
    };
  }
  return [
    // assemblyCode is the letter C in this case
    {
      year: vehicle?.year || constants.EMPTY_STRING,
      model: vehicle?.model || constants.EMPTY_STRING,
      make: vehicle?.make || constants.EMPTY_STRING,
      // NOTE: hotfix: removing the pre populate for vtv incomplete
      // vin: vehicle?.vin || constants.EMPTY_STRING,
      // licensePlate: vehicle?.licensePlate || constants.EMPTY_STRING,
      // licenseState: vehicle?.licensePlateState ? vehicle.licensePlateState : constants.EMPTY_STRING,
      color: vehicle?.color || constants.EMPTY_STRING,
      chassisId: vehicle?.chassisId || vehicle?.chassisCode || constants.EMPTY_STRING,
      trimLift: vehicle?.fitmentLiftedId || constants.EMPTY_STRING,
      trimId: vehicle?.trimId || constants.EMPTY_STRING,
      trimDescription: vehicle?.trimName || constants.EMPTY_STRING,
      // imageUrl: vehicle?.imageURL || constants.EMPTY_STRING,
      trim: vehicle?.trimDescription || vehicle?.trimName || constants.EMPTY_STRING,
      isDually: vehicle?.isDualRearWheel || false,
      isStaggered: vehicle?.isStaggered || false,
      // fitmentID nested slectedAssmb
      isActive: true,
      isOneTime: false,
    },
    vehicle
  ];
};
export default formatMileage;
