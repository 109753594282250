import React from 'react';
import styled from '../../helpers/esm-styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import { theme } from '../../helpers/GlobalStyles';

const PillContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.pillGray};
  border-radius: 16px;
  height: 24px;
  min-width: 67px;
  padding: 0 12px;
  margin: 0 4px;
  font-size: ${theme.fontSize.medium};
  color: ${props => props.isClear ? theme.colors.red : theme.colors.darkGray};
  font-weight: 600;
  white-space: nowrap;
`;

const PillContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.red};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const CloseIcon = styled(AiOutlineClose)`
  color: ${theme.colors.red};
  font-size: 12px;
`;

const FilterPill = ({ label, onRemove, isClear = false }) => {
  return (
    <PillContainer isClear={isClear}>
      <PillContent>
        {label ?? 'Unknown'}
        <CloseButtonWrapper onClick={onRemove} aria-label={`Remove filter: ${label}`}>
          <CloseIcon />
        </CloseButtonWrapper>
      </PillContent>
    </PillContainer>
  );
};

export default FilterPill;