import API from "dt-cvm-api";
import { countryOptions, setCachedCVData } from '../helper/baseDataHelper';
import { deepObjectCopy } from "../helper/CustomerHelper";
import { isNullEmptyUndefined } from "../helper/validationHelper";
import * as constants from '../constants/Constants';
import { addUpdateCustomerVehicle } from "../UIComponents/Modals/VehicleModals/StandardVehicle/VehicleHelper";
export const gkCustomerPush = (customer) => {
  // TO DO: This will need to be restructured to fulfill requirements for GK push
  // Will currently set whatever customer is passed primary/alternate into iniitial structure
  const phoneCollection = [];
  const phones = [customer?.phoneNumber, customer?.mobileNumber, customer?.workNumber];
  phones.forEach((phone, i) => {
    if (phone) {
      phoneCollection.push({
        phoneItem: {
          phoneType: i === 0 ? constants.HOME_PHONE : i === 1 ? constants.MOBILE_PHONE : constants.WORK_PHONE,
          phoneNumber: phone,
        }
      });
    }
  });
  customer.phoneCollection = phoneCollection;
  // strip company name from object if is present and is B2C (handles legacy customers)
  const empId = customer?.alternateIdentifierCollection?.find(ai => ai.alternateIdentifierItem?.name === 'EMPLOYEE ID');
  if (customer.customerType === constants.STANDARD_CUSTOMER_TYPE && empId) {
    customer.companyName = constants.EMPTY_STRING;
  }
  return customer;
};
export const setCarryOutFields = (
  standardVehicleFields,
  selectedVehicle,
  isEdit,
  setSelectedVehicle,
  carryOutFieldsCallback,
) => {
  if (isEdit && standardVehicleFields?.isCarryOut) {
    const carryOutModified = { ...selectedVehicle };
    carryOutModified.vehicleDetails.vin = constants.CARRYOUT;
    carryOutModified.vehicleDetails.licensePlateNumber = constants.CARRYOUT;
    carryOutModified.vehicleDetails.licenseState = "AZ";
    carryOutModified.vehicleDetails.isCarryOut = true;
    carryOutModified.vehicleDetails.isIncomplete = false;
    carryOutFieldsCallback && carryOutFieldsCallback({
      ...selectedVehicle,
      vehicleDetails: carryOutModified?.vehicleDetails,
    });
    setSelectedVehicle({
      ...selectedVehicle,
      vehicleDetails: carryOutModified?.vehicleDetails,
    });
  };
};
// structures the data and checks to see if there are any AR Charge Fields that don't have values. If they don't have values, return an error and pop modal
export const prepDataForSend = (transactionCustomer, countryCodeOptions, selectedVehicle, customerInfo, standardVehicleFields, isEdit, setIsModalOn, setSelectedVehicle, setApplyCustomerVehicleError) => {
  // Customer conversion if the transaction customer info changed
  let customer = deepObjectCopy(customerInfo);
  if (transactionCustomer) {
    if (typeof transactionCustomer.state === 'object') transactionCustomer.state = transactionCustomer.state.value;
    // Needs the country code to be set as "US" vs "USA" this will be resolved in refactor
    if (typeof transactionCustomer.country === 'object') {
      transactionCustomer.country = transactionCustomer.country.value === countryOptions[0].value
        ? countryCodeOptions[0].value : transactionCustomer.country.value;
    }
    customer = { ...customer, ...transactionCustomer };
  }
  customer = gkCustomerPush(customer);
  let vehicle = null;
  if (selectedVehicle) {
    vehicle = selectedVehicle;
    if (!isNullEmptyUndefined(selectedVehicle?.inspection)) {
      vehicle.inspection = selectedVehicle.inspection;
    }
  }
  const requiredFields = {
    '07': 'year',
    '08': 'make',
    '09': 'model',
    '10': 'licensePlateNumber',
    '11': 'licenseState',
    '12': 'vin',
  };
  // cycle through the characteristic collection to determine if the customer has any AR Charge Fields that aren't filled out
  const errors = customerInfo?.characteristicCollection?.reduce((errors, cc) => {
    const field = requiredFields[cc.characteristicItem.code];
    if (field && isNullEmptyUndefined(vehicle.vehicleDetails[field])) {
      if (!errors.includes(field)) {
        errors.push(field);
      }
    }
    return errors;
  }, []) || [];
  //(standardVehicleFields.isCarryOut && arChargeFields.length) && doneWithCarryout(customer, customerInfo, standardVehicleFields, selectedVehicle, isEdit, transactionCustomer, setIsModalOn, setSelectedVehicle);
  //
  if (errors.length > 0) {
    setApplyCustomerVehicleError(true);
    return;
  }
  return { customer, vehicle };
};
const sendDataToGK = async (data, customerInfo, selectedVehicle, vtvSelected, aircheckSelected, setLoader, setCurrentCV, setIsCVUpdated, APIRouter) => {
  if (data.customer && data.vehicle) {
    try {
      await APIRouter('GK', 'applyCustomerVehicle', data, setLoader);
      setCurrentCV({ customer: customerInfo, vehicle: selectedVehicle, inspection: vtvSelected || aircheckSelected });
      setIsCVUpdated(false);
    } catch (e) {
      console.error(e);
    }
  }
};
const setFieldsForCarryOut = (vehicle) => {
  // set hard-coded values for carry out
  vehicle.vin = 'carryout';
  vehicle.license = 'carryout';
  vehicle.licenseState = 'AZ';
  vehicle.licenseCountry = 'USA';
};
export const proceedWithCarryOut = async (customer, vehicle, setIsModalOn) => {
  vehicle.isCarryOut = true;
  setFieldsForCarryOut(vehicle);
  await publishCVData(null, { customer, vehicle });
  setIsModalOn(false);
};
const sendDataToListener = async (data) => {
  if (data.customer && data.vehicle) {
    try {
      window.parent.postMessage(data, '*');
    } catch (e) {
      console.error(e);
    }
  }
};
//
// Structures the CV Data, sets the cache and sends the data to GK
//
export const publishCVData = async (_e, byPassedData, customerInfo,
  selectedVehicle, vtvSelected, aircheckSelected, transactionCustomer,
  standardVehicleFields, isEdit, setIsModalOn, setSelectedVehicle,
  countryCodeOptions, setApplyCustomerVehicleError, setLoader, setCurrentCV,
  setIsCVUpdated, APIRouter) => {
  let data;
  if (!byPassedData) {
    // structure the data. If there's an error associated with missing values for AR charge fields, it'll stop the execution and pop the vehicle modal
    data = prepDataForSend(transactionCustomer, countryCodeOptions, selectedVehicle, customerInfo, standardVehicleFields, isEdit, setIsModalOn, setSelectedVehicle, setApplyCustomerVehicleError);
    if (API.utils.notValid(data)) return;
  } else {
    // Even with byPassedData, ensure customer data is properly formatted
    const customer = gkCustomerPush(byPassedData.customerInfo);
    data = {
      customer,
      vehicle: byPassedData.selectedVehicle
    };
  }
  //  Store the data in the CV cache so that getTransactionCV call results can
  //  potentially reload the customer & vehicle without talking to the server.
  //  NOTE: this data should mirror the 'currentCV' data structure.
  // const { SRPMICNumber, ...customerWithoutSRPMICNumber } = customerInfo;
  setCachedCVData({ customer: customerInfo, vehicle: selectedVehicle, inspection: vtvSelected || aircheckSelected });
  //  Format it like how GK likes it
  const formattedData = await API.transforms.transformGKData(
    data.customer, data.vehicle ? data.vehicle : data.selectedVehicle,
    localStorage.getItem('schemaVersion') || constants.DEFAULT_SCHEMA_VERSION,
  );
  // CVM-1793
  // Removed B2B phone extensions for PB since they aren't ready to handle phone extensions,
  // so I guess we have to do it :)
  if (formattedData) {
    formattedData?.customer.businessPrimaryPurchasingPhoneCollection?.forEach(item => {
      delete item.businessPhoneExtension;
    });
  }
  //  Are we running in GK? 
  if (API.GK.hasPOS()) {
    console.log('Sending data to GK POS');
    const sendResult = sendDataToGK(
      formattedData,
      customerInfo,
      selectedVehicle,
      vtvSelected,
      aircheckSelected,
      setLoader,
      setCurrentCV,
      setIsCVUpdated,
      APIRouter);

    return sendResult;
  }
  //  Are we running in an iframe?
  const runningInIFrame = window.location !== window.parent.location;
  if (runningInIFrame) {
    console.log('Sending data to iframe');
    const sendResult = sendDataToListener(formattedData);
    return sendResult;
  }
  console.log('No data target (POS or iframe)');
};
