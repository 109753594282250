/* eslint-disable import/no-cycle */

import React, {
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// import env from '@beam-australia/react-env';
import debug from 'debug';
import StorageShim from 'node-storage-shim';

import * as constants from '../../constants/Constants';

import {
  deepObjectCopy,
  processCustomerVehicles,
} from '../../helper/CustomerHelper';

import {
  breadCrumbs,
  getCachedCVData,
  defaultInspectionQuery,
  initialVehicleFormData,
  initialCustomerFormData,
  initialStandardVehicleFormData,
  objectsAreEqual,
} from '../../helper/baseDataHelper';

import {
  customerRestructure,
  formatNumeric,
  getPrimaryInfo,
  isEmptyArray,
  isNullEmptyUndefined,
  phoneCustomerCycle,
} from '../../helper/validationHelper';

import Loader from '../../UIComponents/Loader/Loader';
import { APIRouter } from '../../Data/APIRouter';
import { API, APILoader } from '../../Data/API';

import {
  NavigationMap, NavigationParams, NavigationQueryMap, isOktaRedirect
} from '../../constants/Navigation';

import ErrorRouter from '../ErrorPages/ErrorRouter';
import Toastr from '../../UIComponents/Toastr/Toastr';

import Header from '../Header/Header';
import Details from '../Details/Details';
import Modal from '../../UIComponents/Modals/Modal';
import { searchMetaData } from '../../Data/searchMetaData';
import { customerVehicleDetails } from '../../helper/vehicleDetailsHelper';

import {
  deactivateOneTimeUseVehicles,
  isVehicleActive,
  splitActiveInactiveVehicles
} from '../../UIComponents/Modals/VehicleModals/StandardVehicle/VehicleHelper';

import { getVehicleInfo } from '../../helper/vehicleFormValidation';

// Standard logger definition
const logger = debug('api');

const localStorage = globalThis.localStorage || new StorageShim();
const sessionStorage = globalThis.sessionStorage || new StorageShim();

// Create shared context for CVM data
export const CVMContext = createContext();

const CVM = () => {

  // Define baseline state management functions
  const [addSelected, setAddSelected] = useState(null);
  const [aircheckSelected, _setAircheckSelected] = useState(null);
  const [csrSelected, _setCSRSelected] = useState(null);
  const [currentBreadCrumb, setCurrentBreadCrumb] = useState(0);
  const [currentViewElement, setCurrentViewElement] = useState(null);
  const [customerInfo, _setCustomerInfo] = useState({});
  const [deactivatedVehicles, setDeactivatedVehicles] = useState([]);
  const [didSkipResults, setDidSkipResults] = useState(false);
  const [showViewAllVehiclesLink, setShowViewAllVehiclesLink] = useState(false);
  const [shouldRunOnSelectedVehicleChange, setShouldRunOnSelectedVehicleChange] = useState(true);
  const [dvrSelected, setDVRSelected] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageObj, setErrorMessageObj] = useState({});
  const [genericVehicleToggle, setGenericVehicleToggle] = useState(false);
  const [gkTransactionCV, setGkTransactionCV] = useState({});
  const [gkTransactionInfo, setGkTransactionInfo] = useState({});
  const [isExceedLimit, setIsExceedLimit] = useState(false);
  const [isManualVtv, _setIsManualVtv] = useState(false);
  const [isModalOn, setIsModalOn] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalDetails, setModalDetails] = useState({});
  const [moduleName, _setModuleName] = useState(constants.MODULE_SEARCH);
  const [previousOrigin, setPreviousOrigin] = useState({});
  const [searchActive, setSearchActive] = useState(false);
  const [searchOrigin, setSearchOrigin] = useState(null);
  const [searchQuery, setSearchQuery] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [selectedVehicle, _setSelectedVehicle] = useState();
  const [showDeactivatedVehicles, setShowDeactivatedVehicles] = useState(false);
  const [transactionCustomer, setTransactionCustomer] = useState(null);
  const [vehSelected, setVehSelected] = useState(null);
  const [vehicleToggle, setVehicleToggle] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [vtvCanSave, setVtvCanSave] = useState(false);
  const [vtvSelected, _setVtvSelected] = useState(null);
  const [importedVehicle, setImportedVehicle] = useState(null);

  const [currentCV, setCurrentCV]  = useState({});
  const [isCVUpdated, setIsCVUpdated]  = useState(true);

  // GK listener for customer/vehicle/inspection updates
  useEffect(() => {
    const customer = customerInfo;
    const vehicle = selectedVehicle;
    const inspection = vtvSelected || aircheckSelected;
    if (customer && vehicle) {
      if (objectsAreEqual(currentCV, { customer, vehicle, inspection })) {
        setIsCVUpdated(false);
      } else {
        setIsCVUpdated(true);
      }
    }
  }, [selectedVehicle, customerInfo, vtvSelected, aircheckSelected]);

  // for clear Filters
  const [filters, setFilters] = useState(0);
  const [filtersTurnedOn, setFiltersTurnedOn] = useState({ isRadioOn: false, isCalendarOn: false, isTireTreadFilterOn: false, isTireAgeFilterOn: false });
  const [selectedValue, setSelectedValue] = useState('both');
  const [filter, setFilter] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());


  // Define baseline form field data
  const [customerFields, _setCustomerFields] = useState(initialCustomerFormData);
  const [customerUpdate, setCustomerUpdate] = useState(false);
  const [standardVehicleFields, _setStandardVehicleFields] = useState({});
  const [vehicleBreadCrumbs, setVehicleBreadCrumbs] = useState(deepObjectCopy(breadCrumbs));
  const [vehicleFields, setVehicleFields] = useState(initialVehicleFormData);

  // Define baseline loader state
  const [loader, setLoader] = useState({ isLoading: false, isModal: false });

  // Define baseline error handlers / state
  const [applyCustomerVehicleError, setApplyCustomerVehicleError] = useState(false);
  const [applicationError, setApplicationError] = useState({
    errorCode: constants.EMPTY_STRING,
    errorType: constants.EMPTY_STRING,
    errorStack: constants.EMPTY_STRING,
    message: constants.EMPTY_STRING,
  });

  // Define baseline toastr (notifier) state
  const [toastrOn, setToastrOn] = useState(false);

  const [isAllVehiclesView, setIsAllVehiclesView] = useState(false);

  const [navInfo, setNavInfo] = useState({});

  const modalRef = useRef(null);

  // Utility function to determine if a result set exceeds the result limit.
  const exceedLimit = (result) => {
    if (result?.length > constants.RESULTS_LIMIT) {
      setIsExceedLimit(true);
      return result.slice(0, constants.RESULTS_LIMIT + 1);
    }
    setIsExceedLimit(false);
    return result;
  };

  const filterKeys = (obj, arr) => {
    const result = { ...obj };
    Object.keys(obj).forEach((key) => {
      if (!arr.includes(key)) {
        delete result[key];
      }
    });
    return result;
  };


  // TODO: move to helper?
  // Utility to handle query values which need to be cleansed before
  // we inject them into the search query.
  const cleanseQueryValues = (params) => {
    if (API.utils.isEmpty(params)) {
      return {};
    }

    const cleanse = (value) => {
      let val;

      val = decodeURIComponent(value);
      val = val.trim();

      if (val.startsWith('"') && val.endsWith('"')) {
        val = val.substring(1, val.length - 1);
      }

      // TODO: watch for illegal characters here, should just be alphanumeric
      // plus '@', '.', and '_' etc. for email and address values.

      return val;
    };

    const cleansedParams = {};

    Object.keys(params).forEach((paramName) => {
      cleansedParams[paramName] = cleanse(params[paramName]);
    });

    return cleansedParams;
  };

  const defaultQueryValues = (params) => {
    if (API.utils.isEmpty(params)) return {};

    const defaulted = { ...params };

    // Ensure the dropdown values are set up for the search query to get past
    // the validation in the UI.

    // Country first so we can pass value to state normalization.
    defaulted.country = API.transforms.normalizeCountry(
      defaulted.country || constants.UNITED_STATES
    );
    defaulted.country = {
      value: defaulted.country,
      label: API.transforms.expandCountry(defaulted.country) || defaulted.country,
    };

    defaulted.state = API.transforms.collapseState(
      defaulted.state || localStorage.getItem('stateCode') || '',
      defaulted.country.value
    );
    defaulted.state = {
      value: defaulted.state,
      label:
        API.transforms.expandState(defaulted.state, defaulted.country.value) ||
        defaulted.state,
    };

    // NOTE: for some reason we do the following pair during navigation.
    // Probably should migrate them all to one location. In any case, we only
    // want string value updates here, not dropdown object sources.

    defaulted.licenseCountry = API.transforms.normalizeCountry(
      defaulted.licenseCountry || defaulted.country.value
    );

    defaulted.licenseState = API.transforms.collapseState(
      API.transforms.normalizeStateCode(
        defaulted.licenseState || defaulted.state.value,
        defaulted.licenseCountry
      )
    );

    return defaulted;
  };

  /**
   * @method processFrontEndParams
   * @summary Processes the front end parameters into where they need to go.
   */
  const processFrontEndParams = async (params) => {

    if (params.state_code) {
      localStorage.setItem('siteState', params.state_code);
      localStorage.setItem('stateCode', params.state_code);
    }

    if (params.schema_version) {
      localStorage.setItem('schemaVersion', params.schema_version);
    }

    if (params.store_id) {
      //  NB: This can be either SITENUM or STORECODE
      const storeIdent = params.store_id;

      const response = await API.AVS.getStoreInfo(storeIdent);

      if (API.utils.isEmpty(response)) {
        console.error('Site not found', params.store_id);
      } else {

        //  GK-only
        localStorage.setItem('siteId', storeIdent);

        localStorage.setItem('storeCode', response.storeCode);
        localStorage.setItem('siteState', response.stateProvince);
        localStorage.setItem('AAA', response.isAaaSite);
        localStorage.setItem('SRPMIC', response.isSrpmic);
      }
    }

    //  If these were explicit on the URL, then we force them to those values.

    if (params.AAA) {
      localStorage.setItem('AAA', (params.AAA.toLowerCase() === 'yes').toString());
    }

    if (params.SRPMIC) {
      localStorage.setItem('SRPMIC', (params.SRPMIC.toLowerCase() === 'yes').toString());
    }
  };

  // TODO: move to helper?
  // Utility function to map from url parameter names to searchQuery names.
  const mapParamsToQueryNames = (params) => {
    if (API.utils.isEmpty(params)) return {};

    const mappedParams = {};

    Object.keys(params).forEach((paramName) => {
      const mappedName = NavigationQueryMap[paramName];
      if (API.utils.notEmpty(mappedName)) {
        mappedParams[mappedName] = params[paramName];
      } else {
        mappedParams[paramName] = params[paramName];
      }
    });

    return mappedParams;
  };


  // Navigators are functions specific to navigating to a given module.
  //
  // Each navigator is responsible for configuring the "prereq" data a
  // module relies on during initial module load.
  //
  // For example, the "customer_search" navigator needs to set data for
  // the various search fields which appear on the customer search page.
  //
  // Navigators are invoked by the navigate() function based on data found
  // in a module-navigation map contained in constants/NavigationMap.js.
  const navHelpers = {

    customer_by_id: async (vals) => {

      const promises = [];

      const { customerId, vehicleId } = vals;

      let cachedCVData,
        customer,
        customerVehicles,
        activeVehicles,
        inactiveVehicles;

      if (API.utils.notEmpty(customerId) || API.utils.notEmpty(vehicleId)) {
        //  If we get customer & vehicle, check "Apply C/V" cache to see if
        //  we've got C/V cached for faster page load without server calls.
        cachedCVData = getCachedCVData(customerId, vehicleId);
      }

      if (API.utils.isEmpty(cachedCVData)) {

        promises.push(APIRouter(
          'C360',
          'getCustomer',
          customerId,
          setLoader
        ));

        const queryVals = {};
        if (vals?.vehicle_id) {
          queryVals.vehicleId = vals.vehicle_id;
        } else if (vals?.vin) {
          queryVals.vin = vals.vin;
        }

        queryVals.defaultPageSize = 3;
        queryVals.isActive = true;
        queryVals.normalize = true;

        promises.push(APIRouter(
          'C360',
          'getCustomerVehicles',
          [customerId, queryVals],
          setLoader
        ));

        const results = await Promise.all(promises);

        [customer, customerVehicles] = results;

        //  Unwrap if necessary.
        customer = customer?.customer ? customer.customer : customer;

        if (!customer?.id) {
          return;
        }

        customer = customerRestructure(customer);

        activeVehicles = customerVehicles || [];

        processCustomerVehicles(activeVehicles, deactivateOneTimeUseVehicles);

        //  If we got less than 3 active vehicles, if we get 3 here, then at least
        //  1 has to be inactive.
        if (activeVehicles?.length < 3) {
          const inactivesQueryVals = { ...queryVals };

          //  NB: When this flag is *missing* we are asking for both active and
          //  inactive.
          delete inactivesQueryVals.isActive;

          customerVehicles = await APIRouter(
            'C360',
            'getCustomerVehicles',
            [customerId, inactivesQueryVals],
            setLoader
          );

          //  See if we got an inactives.
          [, inactiveVehicles] = splitActiveInactiveVehicles(customerVehicles);

          processCustomerVehicles(inactiveVehicles, deactivateOneTimeUseVehicles);
        }

        setCustomerInfo(customer);

      } else {
        customer = cachedCVData.customer;
        activeVehicles = [{ ...cachedCVData.vehicle.vehicleDetails }];
        customerVehicles = [{
          vehicleItem: { ...cachedCVData.vehicle.vehicleDetails }
        }];
      }

      setCSRSelected({ id: customer.id || customer.customerId });

      setVehicles(activeVehicles || []);
      setDeactivatedVehicles(inactiveVehicles || []);

      let vehicles;

      //  If a vehicleId was supplied, see if we can find a vehicle and set it
      //  to be selected.
      if (!isNullEmptyUndefined(vals?.vehicleId)) {
        //  TODO: Filter by vehicleId
        vehicles = activeVehicles?.filter((item) => {
          return item.vehicleId === vals.vehicleId;
        });

        setSearchQuery({ vehicleId: vals.vehicleId });
      }

      if (!isNullEmptyUndefined(vals?.vin)) {
        //  TODO: Filter by vin
        vehicles = activeVehicles?.filter((item) => {
          return item.vin === vals.vin;
        });

        setSearchQuery({ vin: vals.vin });
      }

      if (!isNullEmptyUndefined(vehicles)) {
        //  NOTE: This should be an empty Array or an Array with only vehicles
        //  matching the filter, not *all* the vehicles.
        const itemized = vehicles?.map((item) => {
          return { vehicleItem: item };
        });

        setVehSelected({ vehicleCollection: itemized });
      }

      return { customer, vehicles: customerVehicles };
    },
  };

  const navigators = {

    search: async (vals) => {
      let cleansedFields;

      logger('Populating search fields...');

      // default search fields or UI components will complain about going
      // from controlled to uncontrolled.
      const customerFields = { ...initialCustomerFormData, ...vals };

      cleansedFields = filterKeys(customerFields, Object.keys(initialCustomerFormData));

      setCustomerFields(cleansedFields);
      logger('customer field values: ', cleansedFields);

      const vehicleFields = { ...initialVehicleFormData, ...vals };
      cleansedFields = filterKeys(vehicleFields, Object.keys(initialVehicleFormData));

      cleansedFields.licenseCountry = {
        label: API.transforms.expandCountry(cleansedFields.licenseCountry || constants.UNITED_STATES),
        value: cleansedFields.licenseCountry || constants.USA,
      };

      cleansedFields.licenseState = {
        label: API.transforms.expandState(cleansedFields.licenseState || constants.EMPTY_STRING),
        value: cleansedFields.licenseState || constants.EMPTY_STRING,
      };

      setVehicleFields(cleansedFields);
      logger('vehicle field values: ', cleansedFields);

      logger('search values: ', vals);

      try {
        setSearchQuery(vals);
      } catch (e) {
        console.error('Error populating search fields', e);
      }

      setDidSkipResults(false);
    },

    customer_search: async (vals) => {
      let cleansedFields;

      // default search fields or UI components will complain about going
      // from controlled to uncontrolled.
      const customerFields = { ...initialCustomerFormData, ...vals };
      cleansedFields = filterKeys(customerFields, Object.keys(initialCustomerFormData));

      setCustomerFields(cleansedFields);
      logger('Fetching customers for: ', cleansedFields);

      try {
        setSearchQuery(cleansedFields);
      } catch (e) {
        console.error('Error populating search fields', e);
      }

      setDidSkipResults(false);

      setSearchOrigin(constants.SEARCH_ORIGIN_CUSTOMER);
    },

    customer_summary: async (vals) => {
      logger('Fetching customer/vehicle: ', vals);

      if (isNullEmptyUndefined(vals.customerId)) {
        console.error('Invalid customerId for customer_summary');
        return;
      }

      try {
        await navHelpers.customer_by_id(vals);
      } catch (e) {
        console.error('Error fetching customer', e);
      }

      setDidSkipResults(true);

      //  TODO: When we land in the customer summary, we should look for
      //  vehSelected and select it. If it doesn't have details, we should
      //  update the selected vehicle from it.
      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
    },

    customer_add: async (vals) => {
      setDidSkipResults(false);

      setModuleName(constants.MODULE_CREATE_CUSTOMER);
    },

    customer_edit: async (vals) => {
      if (isNullEmptyUndefined(vals.customerId)) {
        console.error('Invalid customerId for customer_edit');
        return;
      }

      try {
        await navHelpers.customer_by_id(vals);
      } catch (e) {
        console.error('Error fetching customer', e);
      }

      setDidSkipResults(true);

      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);

      //  NB: Over in MODULE_CUSTOMER_SUMMARY, we rely on navInfo to do the
      //  second half.
    },

    customer_vehicles: async (vals) => {
      try {
        await navHelpers.customer_by_id(vals);
      } catch (e) {
        console.error('Error fetching customer', e);
      }

      setDidSkipResults(true);

      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);

      //  NB: Over in MODULE_CUSTOMER_SUMMARY, we rely on navInfo to do the
      //  second half.
    },

    vehicle_search: async (vals) => {
      let cleansedFields;

      const vehicleFields = { ...initialVehicleFormData, ...vals };
      cleansedFields = filterKeys(vehicleFields, Object.keys(initialVehicleFormData));

      cleansedFields.licenseCountry = {
        label: API.transforms.expandCountry(cleansedFields.licenseCountry || constants.UNITED_STATES),
        value: cleansedFields.licenseCountry || constants.USA,
      };

      cleansedFields.licenseState = {
        label: API.transforms.expandState(cleansedFields.licenseState || constants.EMPTY_STRING),
        value: cleansedFields.licenseState || constants.EMPTY_STRING,
      };

      setVehicleFields(cleansedFields);
      logger('Fetching vehicles for: ', cleansedFields);

      try {
        setSearchQuery(cleansedFields);
      } catch (e) {
        console.error('Error populating search fields', e);
      }

      setDidSkipResults(false);

      if (isNullEmptyUndefined(cleansedFields.vin)) {
        setSearchOrigin(constants.SEARCH_ORIGIN_VIN);
      } else {
        setSearchOrigin(constants.SEARCH_ORIGIN_LICENSE_PLATE);
      }
    },

    vehicle_select: async (vals) => {
      logger('Fetching customer/vehicle: ', vals);

      if (isNullEmptyUndefined(vals.customerId)) {
        console.error('Invalid customerId for customer_summary');
        return;
      }

      if (isNullEmptyUndefined(vals.vehicleId) && isNullEmptyUndefined(vals.vin)) {
        console.error('Invalid vehicleId/vin for vehicle_select');
        return;
      }

      try {
        await navHelpers.customer_by_id(vals);
      } catch (e) {
        console.error('Error fetching customer', e);
      }

      setDidSkipResults(true);

      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
    },

    vehicle_edit: async (vals) => {

      let customer,
        vehicles;

      if (isNullEmptyUndefined(vals.customerId)) {
        console.error('Invalid customerId for vehicle_edit');
        return;
      }

      if (isNullEmptyUndefined(vals.vehicleId) && isNullEmptyUndefined(vals.vin)) {
        console.error('Invalid vehicleId/vin for vehicle_edit');
        return;
      }

      try {
        const result = await navHelpers.customer_by_id(vals);
        customer = result.customer;
        vehicles = result.vehicles;
      } catch (e) {
        console.error('Error fetching customer', e);
      }

      if (isNullEmptyUndefined(customer)) {
        console.error('Customer not found for vehicle_edit');
        return;
      }

      if (isNullEmptyUndefined(vehicles)) {
        console.error('Vehicle not found for vehicle_edit');
        return;
      }

      //  NB: Over in MODULE_CUSTOMER_SUMMARY, we rely on navInfo to do the
      //  second half.

      setDidSkipResults(true);

      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
    },

  };

  // Handle URL/postMessage-driven navigation to a target module based on
  // module name and any supplied parameters.
  // See constants/NavigationMap.js for the module name -> function mapping.
  // See https://discounttire.atlassian.net/wiki/spaces/OI/pages/5079072875/CVM+URL+parameters for details on URL parameters.
  const navigate = async (target, params) => {
    logger('Handling navigation request...');

    logger('target: ', target);
    logger('params: ', params);

    //  If it's an Okta redirect, there is no navigation to do. Just return
    //  here.
    if (isOktaRedirect(window.location)) {
      logger('Ignoring Okta redirect. Nothing to do here.');
      return;
    }

    let goto = target;  //  ;)
    const vals = params || {};

    // Special case for GK POS, we get a uuid but nothing else. In that
    // case it's really just cache-busting, so we can ignore it.
    if (API.utils.isEmpty(goto) &&
        Object.keys(vals).length === 1 && vals.uuid) {
      logger('Ignoring GK POS cache-busting navigation.');
      return;
    }

    // If no module name is provided, but we got params, we default based on
    // the presence of a customer ID. If we have a customer ID we go directly
    // to the customer summary page, otherwise we go to the search page.
    if (API.utils.isEmpty(goto) && API.utils.notEmpty(vals)) {
      goto = API.utils.notEmpty(vals.customer_id) ? 'customer_summary' : 'search';
      logger(`No module name provided, defaulting to ${goto}.`);
    }

    if (API.utils.isEmpty(NavigationMap[goto])) {
      logger(`No navigation mapping found for ${goto}.`);
      return;
    }

    //  Be sure to call this before calling 'cleanse' as there may be parameters
    //  that it needs.
    await processFrontEndParams(vals);

    const mapped = mapParamsToQueryNames(vals);
    // logger('mapped: ', mapped);

    const cleansed = cleanseQueryValues(mapped);
    // logger('cleansed: ', cleansed);

    const defaulted = defaultQueryValues(cleansed);
    // logger('defaulted: ', defaulted);

    const navigatorName = NavigationMap[goto]?.method || goto;
    logger('navigatorName: ', navigatorName);

    const navigator = navigators[navigatorName];
    if (typeof navigator === 'function') {
      setNavInfo({
        target,
        params: { ...defaulted }
      });

      await navigator(defaulted);
      return;
    }

    logger(`Navigation to ${goto} not yet implemented.`);
  };

  // ---
  // Context Setter Overrides
  // ---

  const setAircheckSelected = (aircheck) => {
    _setAircheckSelected(aircheck);
  };

  const setCustomerFields = (customerFields) => {
    _setCustomerFields(customerFields);
  };

  const setCSRSelected = (csr) => {
    _setCSRSelected(csr);
  };

  const setCustomerInfo = (customerInfo) => {
    const info = { ...customerInfo };
    info.SRPMICNumber = info.SRPMICNumber ||
      sessionStorage.getItem(`${info?.id}.SRPMICNumber`);
    _setCustomerInfo(info);
  };

  const setIsManualVtv = (vtv) => {
    _setIsManualVtv(vtv);
  };

  const setModuleName = (name) => {
    _setModuleName(name);
  };

  const setSelectedVehicle = (vehicle) => {
    _setSelectedVehicle(vehicle);
  };

  const setVtvSelected = (vtv) => {
    _setVtvSelected(vtv);
  };

  const setStandardVehicleFields = (fields) => {
    _setStandardVehicleFields(fields);
  };

  // ---
  // Public Context Data
  // ---

  // Define context-accessible data management functions
  const cvmData = {
    addSelected,
    aircheckSelected,
    applicationError,
    applyCustomerVehicleError,
    csrSelected,
    currentBreadCrumb,
    currentViewElement,
    customerFields,
    customerInfo,
    customerUpdate,
    deactivatedVehicles,
    didSkipResults,
    dvrSelected,
    errorMessage,
    errorMessageObj,
    genericVehicleToggle,
    gkTransactionCV,
    gkTransactionInfo,
    importedVehicle,
    isAllVehiclesView,
    isExceedLimit,
    isManualVtv,
    isModalOn,
    isToggle,
    loader,
    modalContent,
    modalDetails,
    moduleName,
    navigate,
    navInfo,
    NavigationParams,
    previousOrigin,
    searchActive,
    searchOrigin,
    searchQuery,
    searchResults,
    selectedVehicle,
    filter,
    filters,
    selectedValue,
    selectedDate,
    filtersTurnedOn,
    shouldRunOnSelectedVehicleChange,
    setShouldRunOnSelectedVehicleChange,
    setFilter,
    setFilters,
    setSelectedValue,
    setSelectedDate,
    setFiltersTurnedOn,
    setAddSelected,
    setAircheckSelected,
    setApplicationError,
    setApplyCustomerVehicleError,
    setCSRSelected,
    setCurrentBreadCrumb,
    setCurrentViewElement,
    setCustomerFields,
    setCustomerInfo,
    setCustomerUpdate,
    setDeactivatedVehicles,
    setDVRSelected,
    setErrorMessage,
    setErrorMessageObj,
    setGenericVehicleToggle,
    setGkTransactionCV,
    setGkTransactionInfo,
    setImportedVehicle,
    setIsAllVehiclesView,
    setIsExceedLimit,
    setIsManualVtv,
    setIsModalOn,
    setIsToggle,
    setLoader,
    setModalContent,
    setModalDetails,
    setModuleName,
    setNavInfo,
    setPreviousOrigin,
    setSearchActive,
    setSearchOrigin,
    setSearchQuery,
    setSearchResults,
    setSelectedVehicle,
    setShowDeactivatedVehicles,
    setShowViewAllVehiclesLink,
    setStandardVehicleFields,
    setToastrOn,
    setTransactionCustomer,
    setVehSelected,
    setVehicleBreadCrumbs,
    setVehicleFields,
    setVehicleToggle,
    setVehicles,
    setVtvCanSave,
    setVtvSelected,
    showDeactivatedVehicles,
    showViewAllVehiclesLink,
    standardVehicleFields,
    toastrOn,
    transactionCustomer,
    vehSelected,
    vehicleBreadCrumbs,
    vehicleFields,
    vehicleToggle,
    vehicles,
    vtvCanSave,
    vtvSelected,
    currentCV,
    setCurrentCV,
    isCVUpdated,
    setIsCVUpdated
  };

  // Primary useEffect hook for CVM
  useEffect(() => {
    debug('ui')('CVM', 'useEffect', 'searchOrigin', 'searchQuery');

    let result = [];

    async function runSearchOrigin() {
      switch (searchOrigin) {
        case constants.SEARCH_ORIGIN_VTV:
          // fallthrough
        case constants.SEARCH_ORIGIN_AIRCHECK:
          try {
            // WIP: this is prelim, using it to see workaround to query for customer, then create/fetch after
            result = await APIRouter('VTV', searchOrigin === constants.SEARCH_ORIGIN_VTV ?
              'getVTVServices' : 'getVTVAirchecks', searchQuery || defaultInspectionQuery(), setLoader);

            result = exceedLimit(result);
            setSearchResults(result);
            setModuleName(constants.MODULE_VEHICLE_SEARCH);
            break;
          } catch (error) {
            console.error(error);
            break;
          }

        case constants.SEARCH_ORIGIN_CUSTOMER:
          // fallthrough
        case constants.SEARCH_ORIGIN_VIN:
          // fallthrough
        case constants.SEARCH_ORIGIN_LICENSE_PLATE:
          if (searchOrigin === constants.SEARCH_ORIGIN_CUSTOMER) {
            if (typeof searchQuery.stateOrProvince === 'object') {
              searchQuery.state = searchQuery.state.value;
            }
            delete searchQuery.stateOrProvince;
            delete searchQuery.phoneCountryCode;
            delete searchQuery.phoneType;
            delete searchQuery.isMilitary;
            delete searchQuery.isAddressValidated;
            searchQuery.metaData = searchMetaData.customerSearch.fields;
            try {
              setLoader({ isLoading: true, isModal: false });
              //  result = await APIRouter('C360', 'getCustomers', searchQuery, setLoader, false, setApplicationError);
              result = await API.C360.getCustomers(searchQuery);
            } catch (e) {
              // Error handling modals
              console.error(e);

              if (e?.cause?.code === 404) {
                result = [];
              }
            } finally {
              setLoader({ isLoading: false, isModal: false });
            }
          } else {
            try {
            // force lowercase due to endpoint not handling case-insensitive
              result = await APIRouter('C360', 'getCustomersByVehicle', searchQuery, setLoader, false);
              result = result?.customerCollection;
              result = exceedLimit(result);
              if (API.utils.notEmpty(result) && Array.isArray(result)) {

                const results = [];
                result.forEach(customer => {
                  if (isVehicleActive(customer?.customerItem?.vehicleCollection?.[0]?.vehicleItem)) {
                    results.push(customerVehicleDetails(customer.customerItem, { ...searchQuery }));
                  }
                });

                if (!isEmptyArray(results)) {
                  setSearchResults(results);
                  setModuleName(constants.MODULE_VEHICLE_SEARCH);
                } else {
                  result = [];
                }
              }
            } catch (e) {
              // Error handling modals
              console.error(e);
              break;
            }
          }

          //  Start common logic

          result = exceedLimit(result);
          if (Array.isArray(result) && API.utils.notEmpty(result)) {
            let results = result.map((customer) => {

              let c = deepObjectCopy(customer);
              if (c.customerItem) {
                c = c.customerItem;
              }

              c = {
                ...c,
                ...customerRestructure(c),
                ...getPrimaryInfo(c.addressCollection),
                ...getPrimaryInfo(c.contactCollection ? c.contactCollection : c.emailCollection)
              };

              if (searchOrigin !== constants.SEARCH_ORIGIN_CUSTOMER) {
                c = { ...c, ...c.vehicleCollection?.[0]?.vehicleItem };
              }
              if (searchOrigin !== constants.SEARCH_ORIGIN_CUSTOMER && !c.isActive) return;

              c.phoneNumber = searchQuery?.workNumber ?
                formatNumeric(phoneCustomerCycle(searchQuery.workNumber, c)) :
                formatNumeric(phoneCustomerCycle({ ...searchQuery }, c));
              return c;
            });

            results = results.filter(c => !isNullEmptyUndefined(c));

            setSearchResults(results);
            setModuleName(searchOrigin === constants.SEARCH_ORIGIN_CUSTOMER ? constants.MODULE_CUSTOMER_SEARCH : constants.MODULE_VEHICLE_SEARCH);
          }
          break;
        default:
          break;
      }

      if (API.utils.notEmpty(searchOrigin)
          && API.utils.isEmpty(result)
          && searchOrigin !== constants.SEARCH_ORIGIN_AIRCHECK
          && searchOrigin !== constants.SEARCH_ORIGIN_VTV
          && searchOrigin !== constants.SEARCH_ORIGIN_CREATE_CUSTOMER) {
        setErrorMessageObj({
          message: searchOrigin === constants.SEARCH_ORIGIN_CUSTOMER
            ? constants.NO_CUSTOMER_FOUND
            : constants.NO_VEHICLE_FOUND,
          searchBy: searchOrigin || constants.EMPTY_STRING,
          type: constants.WARNING,
        });

        if (!searchOrigin) setSearchOrigin(null);
      }
    }

    runSearchOrigin();

  }, [searchOrigin, searchQuery]);

  // Render CVM's core user interface components
  return (
    <CVMContext.Provider value={cvmData}>
      {applicationError?.message && <ErrorRouter {...applicationError} /> }
      { loader?.isLoading && !loader.isModal && <Loader loadingText={loader.loadingText} />}
      <APILoader>
        {isModalOn && <Modal modalRef={modalRef} type={modalDetails.type}   />}
        <Header />
        <Details />
      </APILoader>
      { toastrOn && <Toastr /> }
    </CVMContext.Provider>
  );
};
export default CVM;
