import {
  formatNumeric,
  formatAlphaNumeric,
  formatLetters,
  checkEmailAddress,
  checkPhoneNumber,
  checkForEmptyObject,
  formatAlphaNumericAndSpace,
  formatLettersAndSpace,
  upperCaseFirstLetter,
  removePhoneMask,
  isNullEmptyUndefined,
  formatName,
} from './validationHelper';
import * as constants from '../constants/Constants';
import getModalData from './modalDataHelper';
import { countryCodeOptions, countryOptions } from './baseDataHelper';
import { APIRouter } from '../Data/APIRouter';

export const customerFormValidation = (value, fieldName, country) => {
  switch (fieldName) {
    case 'fleetNumber':
    case 'employeeID':
    case 'AAA':
      return formatNumeric(value);
    case 'SRPMICNumber':
      return formatAlphaNumeric(value);
    case 'address1':
    case 'address2':
      return formatAlphaNumericAndSpace(value);
    case 'firstName':
    case 'lastName':
      return formatName(value);
    case 'middleName':
      return formatLetters(value).toUpperCase();
    case 'city':
      return formatLettersAndSpace(value);
    case 'zip':
      return country === 'USA' ? formatNumeric(value) : formatAlphaNumericAndSpace(value);
    case 'companyName':
      return formatName(value, true);
    case 'phoneNumber':
    case 'mobileNumber':
    case 'workNumber':
      value = value.replace('+52 ', constants.EMPTY_STRING);
      value = value.replace('+1 ', constants.EMPTY_STRING);
      return value;
    default:
      return value;
  }
};
export const addressVerification = async (fields, setLoader) => {
  const enteredFields = { ...fields };
  enteredFields.address1 = upperCaseFirstLetter(enteredFields?.address1);
  enteredFields.address2 = upperCaseFirstLetter(enteredFields?.address2);
  enteredFields.city = upperCaseFirstLetter(enteredFields?.city);
  enteredFields.state = fields.state.value;
  // Country not consistent
  if (typeof enteredFields.country === 'object') enteredFields.country = fields.country.value;
  if (enteredFields.country === countryOptions[0].label) enteredFields.country = countryOptions[0].value;
  if (enteredFields.country === countryOptions[0].value) {
    try {
      let correctedAddress = await APIRouter('AVS', 'getCorrectedAddress', {
        address1: enteredFields.address1,
        address2: enteredFields?.address2,
        city: enteredFields?.city,
        state: enteredFields?.state,
        country: enteredFields?.country,
        zip: enteredFields?.zip
      }, setLoader);
      const {
        addressLine1, addressLine2, city, state, country, zipCode,
      } = correctedAddress?.address;
      correctedAddress.address1 = addressLine1;
      correctedAddress.address2 = addressLine2;
      correctedAddress.city = city;
      correctedAddress.state = state;
      // This is filtered with country options since AVS returns a value not a label
      correctedAddress.country = countryOptions.filter(c => c.value === country)[0].label;
      correctedAddress.zip = zipCode;
      correctedAddress = { ...fields, ...correctedAddress };
      // Checks if only the zip ext changed, if any other changed will set interaction modal w/ values
      if (correctedAddress.address1 === enteredFields.address1
        && correctedAddress.address2 === enteredFields.address2
        && city === enteredFields.city
        && state === enteredFields.state
        && country === enteredFields.country
        && correctedAddress.zip.slice(0, correctedAddress.zip.indexOf('-')) === fields.zip) {
        return [correctedAddress];
      }
      const modalData = getModalData(constants.MODAL_TYPE_ADDRESS);
      modalData.values = [{ ...correctedAddress, isAddressValidated: true }, { ...enteredFields, isAddressValidated: false }];
      return modalData;
    } catch (e) {
      // This is where USPS unavailable modal
      const modalData = getModalData(constants.MODAL_TYPE_ONE_ADDRESS);
      modalData.values = [null, { ...enteredFields, isAddressValidated: false }];
      return modalData;
    }
  } else {
    return [{ ...enteredFields, isAddressValidated: true }];
  }
};

export const fetchZip = async (fields, setFields, setLoader, getStateOptions) => {
  let zipObj;
  const isSixChars = fields.zip.length === 6;
  if (fields?.zip?.length === 5 || fields.zip.length === 7 || isSixChars) {
    // TO DO: update the city input to dropdown if the zip returns more than one option
    try {
      zipObj = await APIRouter('AVS', 'getZipCodeInfo', !isSixChars ? fields.zip : `${fields.zip.slice(0, 3)} ${fields.zip.slice(3)}`, setLoader);
      if (zipObj?.locationDetails) {
        zipObj = zipObj.locationDetails?.locationCollection[0].locationItem;
        zipObj.state = getStateOptions(zipObj.country === constants.UNITED_STATES ? null : constants.CA).filter(state => state.value === zipObj?.state)[0];
        zipObj.country = typeof zipObj.country === 'string' ? { value: zipObj.country, label: zipObj.country } : zipObj.country;
        zipObj.country.value = (zipObj.country.label === constants.UNITED_STATES && constants.USA)
        || (zipObj.country.label === constants.MEXICO && constants.MX)
        || (zipObj.country.label === constants.CANADA && constants.CA);
        setFields({
          ...fields,
          country: zipObj.country,
          state: zipObj.state,
          city: zipObj.city,
        });
      }
    } catch (e) { console.error(e); }
  }
};

export const getZipCode = async (value, lookupZipCode) => {
  if ((value.length === 3 || value.length === 5)) {
    try {
      return await lookupZipCode(value)[0];
    } catch (e) {
    // TO DO status codes for error modals
      console.error(e);
    }
  }
  return constants.EMPTY_STRING;
};

export const validateEmailAddress = (email) => {
  if (!email) return true;
  return checkEmailAddress(email);
};

export const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return true;
  return checkPhoneNumber(phoneNumber);
};
export const validateCustomerOnSearch = (customerFields) => {
  const {
    firstName, lastName, fleetNumber, employeeID,
  } = customerFields;
  const errorMessages = {};
  const isEmailValid = validateEmailAddress(customerFields?.email);
  const isPhoneValid = validatePhoneNumber(customerFields?.phoneNumber);
  !isEmailValid && (errorMessages.email = constants.INVALID_EMAIL);
  !isPhoneValid && (errorMessages.phoneNumber = constants.INVALID_PHONE_NUMBER);
  if (!lastName && firstName) {
    errorMessages.firstName = constants.NAMES_REQUIRED;
    errorMessages.lastName = constants.NAMES_REQUIRED;
  }
  if (fleetNumber && fleetNumber.length < 5) {
    errorMessages.fleetNumber = constants.FLEET_REQUIRED;
  }
  if (employeeID && employeeID.length !== 6) {
    errorMessages.employeeID = constants.EMPLOYEEID_REQUIRED;
  }
  return errorMessages;
};

export const validateCustomerOnCreate = customerFields => {
  const {
    firstName,
    lastName,
    address1,
    state,
    zip,
    phoneNumber,
    phoneType,
    country,
    city,
  } = customerFields;
  const isEmailValid = validateEmailAddress(customerFields?.email);
  const isPhoneValid = validatePhoneNumber(customerFields?.phoneNumber);
  const requirements = [firstName, lastName, address1, state, zip, phoneNumber,
    phoneType, country, city].every((field) => !checkForEmptyObject(field));
  return requirements && isEmailValid && isPhoneValid;
};

export const validateContactInfo = (fields, isB2BEdit) => {
  const {
    address1,
    zip,
    city,
    state,
    country,
    email,
    mobileNumber,
    phoneNumber,
    workNumber,
  } = fields;

  const isMobileValid = validatePhoneNumber(removePhoneMask(mobileNumber));
  const isWorkValid = validatePhoneNumber(removePhoneMask(workNumber));
  const isPhoneValid = validatePhoneNumber(removePhoneMask(phoneNumber));
  const isEmailValid = validateEmailAddress(email);
  const inValidNumber = !isB2BEdit ? (!isMobileValid || !isWorkValid || !isPhoneValid) : true;
  const isAllEmpty = (isNullEmptyUndefined(removePhoneMask(mobileNumber)) && isNullEmptyUndefined(removePhoneMask(workNumber)) && isNullEmptyUndefined(removePhoneMask(phoneNumber)));
  const requirements = [address1, zip, city, state, country].every(
    (field) => !checkForEmptyObject(field),
  );
  return requirements && (!isB2BEdit ? isEmailValid && !inValidNumber && !isAllEmpty : true);
};

export const addressChangeChecker = (updatedFields, fields) => {
  if (fields.address1 !== updatedFields.address1
  || fields?.address2 !== updatedFields?.address2
  || fields.zip !== updatedFields.zip
  || fields.state.value !== updatedFields.state.value
  ) {
    return true;
  }
  return false;
};


// Disble funtionality if not a US country
export const taxExemptionApplication = async (info, stateOptions, setLoader) => {
  try {
    const storeState = localStorage.getItem('siteState');
    const mobileNumber = info.phoneCollection.find(phoneItem => phoneItem?.phoneItem?.phoneType === constants.MOBILE_PHONE)?.phoneItem.phoneNumber;
    const phoneNumber = info.phoneCollection.find(phoneItem => phoneItem?.phoneItem?.phoneType === constants.HOME_PHONE)?.phoneItem?.phoneNumber;
    const workNumber = info.phoneCollection.find(phoneItem => phoneItem?.phoneItem?.phoneType === constants.WORK_PHONE)?.phoneItem.phoneNumber;
    const expemptCustomerInfo = {
      taxCustomerNumber: info?.id,
      customerType: info?.customerType,
      attnName: `${info?.firstName} ${info?.lastName}`,
      name: `${info?.firstName} ${info?.lastName}`,
      addressLine1: info?.address1,
      addressLine2: info?.address2,
      city: info?.city,
      state: stateOptions().filter(state => state.value === info?.state)[0].label,
      postalCode: info?.zip,
      region: info?.state,
      country: countryCodeOptions.filter((c) => c.label === info?.country)[0].value,
      phoneNumber: info.customerType === constants.STANDARD_CUSTOMER_TYPE ? info?.mobileNumber || info?.phoneNumber || info?.workNumber : mobileNumber || phoneNumber || workNumber,
      email: info?.email,
      companyName: info?.companyName
    };
    if (isNullEmptyUndefined(info.email)) {
      delete expemptCustomerInfo.email;
    }

    const res = await APIRouter('TAX', 'addTaxExemptCustomer', expemptCustomerInfo, setLoader);
    if (res.requestStatus === 'Success') {
      const storeStateFullName = stateOptions().filter(state => state.value === storeState)[0].label;
      const taxInitiate = await APIRouter('TAX', 'initiateCertificateRequest', [res?.customerReferenceId, storeStateFullName, expemptCustomerInfo.customerType], setLoader);
      return taxInitiate?.wizardUrl;
    }
  } catch (e) {
    console.error(e);
  }
};
